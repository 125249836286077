import React from 'react';
import TabbedNavigation from '../../../components/tabbednavigation';
import { PageHeader, Icon } from '../../../components';
import { Row, Col } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { PlanHeaderProps } from './interface';
import { useIsPaused } from '../../../hooks/useIsPaused';
import { useIsUserTrial } from 'hooks/useIsUserTrial';
import { useCurrentUser } from 'providers/UserProvider';

const pausedAccountTitle = 'settings.plan.paused_account_title';
const pausedAccountSubtitle = 'settings.plan.paused_account_subtitle';

const PaidPlanHeader: React.FC<PlanHeaderProps> = ({ firstName, paused }) => {
  const { t } = useTranslation();

  const billingMenuItems = [
    {
      href: `${process.env.REACT_APP_LEGACY_APP_URL}/settings/summary`,
      external: true,
      target: '_self',
      title: t('settings.plan.non_trial.tabs.plan_summary'),
      icon: <Icon name="IcoMaps" />,
    },
    {
      href: '/settings/plan',
      title: t('settings.plan.non_trial.tabs.view_plans'),
      icon: <Icon name="IcoOrderedList" />,
    },
  ];

  const title = !paused ? 'settings.plan.non_trial.title' : pausedAccountTitle;
  const subtitle = (
    <Trans i18nKey={!paused ? 'settings.plan.non_trial.subtitle' : pausedAccountSubtitle} components={{ newline: <br /> }} />
  );

  const pageHeaderComponent = <PageHeader title={t(title, { first_name: firstName })} paragraph={subtitle} />;

  return (
    <>
      {paused && (
        <Row className="plan-page-header-container">
          <Col flex={1}>{pageHeaderComponent}</Col>
        </Row>
      )}
      <Row className="page-header-container">
        <Col flex={1}>
          {!paused && pageHeaderComponent}
          <TabbedNavigation items={billingMenuItems} mode="horizontal" />
        </Col>
      </Row>
    </>
  );
};

const UnpaidPlanHeader: React.FC<PlanHeaderProps> = ({ firstName, paused }) => {
  const { t } = useTranslation();

  const title = !paused ? 'settings.plan.title' : pausedAccountTitle;
  const subtitle = <Trans i18nKey={!paused ? 'settings.plan.subtitle' : pausedAccountSubtitle} components={{ newline: <br /> }} />;

  return (
    <Row className="plan-page-header-container">
      <Col flex={1}>{firstName && <PageHeader title={t(title, { first_name: firstName })} paragraph={subtitle} />}</Col>
    </Row>
  );
};

const BillingHeader: React.FC = () => {
  const {
    data: { accountId, firstName = '' },
  } = useCurrentUser();
  const isTrial = useIsUserTrial({ accountId, enabled: !!accountId });
  const paused = useIsPaused();

  if (isTrial) {
    return <UnpaidPlanHeader firstName={firstName ?? ''} paused={paused} />;
  }

  return <PaidPlanHeader firstName={firstName ?? ''} paused={paused} />;
};

export default BillingHeader;
