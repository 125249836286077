import React from 'react';
import { useParams } from 'react-router-dom';

import DocumentBuilder from 'components/editor/documentBuilder';
import { useGetVariables } from 'hooks/useVariables';
import { SignaturesProvider } from 'providers/SignaturesProvider';
import { GridBlocksProvider } from 'providers/GridBlocksProvider';
import SocketClient from 'services/socket/SocketClient';
import { UsedVariablesProvider } from '../../providers/UsedVariablesContext';
import { Provider } from 'react-redux';
import { gridBlocksStore } from '../../components/editor/grid/reduxStore/store';
import { SocketProvider } from '../../providers/SocketContext';

const socketClient = new SocketClient();
const EditorPage: React.FC = () => {
  const { documentId } = useParams<{ documentId: string }>();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: variables } = useGetVariables(documentId!, { enabled: documentId != null });

  if (!documentId) {
    throw new Error('Document id missing');
  }

  return (
    <SocketProvider socketClient={socketClient}>
      <Provider store={gridBlocksStore}>
        <GridBlocksProvider>
          <SignaturesProvider documentId={documentId} socketClient={socketClient}>
            <UsedVariablesProvider>
              <DocumentBuilder socketClient={socketClient} documentId={documentId} variables={variables} />
            </UsedVariablesProvider>
          </SignaturesProvider>
        </GridBlocksProvider>
      </Provider>
    </SocketProvider>
  );
};

export default EditorPage;
