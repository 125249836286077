import Icon from 'components/icon';
import React, { forwardRef } from 'react';
import './styles.less';
import type { SignatureColor } from './utils';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: SignatureColor;
}

export const SignatureBaseButton = forwardRef<HTMLButtonElement, Props>(
  ({ color, className = '', draggable = false, children, ...props }, ref) => {
    return (
      <button ref={ref} className={`signature_button user_color ${color} ${className}`} draggable={draggable} {...props}>
        <Icon className="signature_button__icon" name="IcoSignaturePen" />
        <span className="signature_button__content">{children}</span>
      </button>
    );
  }
);

SignatureBaseButton.displayName = 'SignatureBaseButton';
