import { configureStore } from '@reduxjs/toolkit';
import { gridBlockReducer } from './editorSlice';

export const gridBlocksStore = configureStore({
  reducer: {
    gridBlocks: gridBlockReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof gridBlocksStore.getState>;
