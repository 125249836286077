import React, { useContext, useEffect, useRef } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';

import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { editorConfig } from '../helpers/config';
import { VariablesGroup } from '../../../services/entities/VariablesEntity';
import { useTributeOnFroalaInit } from 'hooks/useTributeOnFroalaInit';
import { useUsedVariables } from 'hooks/useUsedVariables';
import { UsedVariablesContext } from '../../../providers/UsedVariablesContext';

export type GridBlockContentChangeHandler = (gridBlockId: string, content: string) => void;
export interface FroalaEditorProps {
  configOptions?: any;
  variables?: VariablesGroup;
  gridBlockId: string;
  toolbarButtons?: any;
  documentContent?: string;
  editorResizeHandler?: (editorId: string, rect: DOMRectReadOnly) => void;
  contentChangeHandler?: GridBlockContentChangeHandler;
}

const FroalaEditorWrapper: React.FC<FroalaEditorProps> = ({
  gridBlockId,
  configOptions,
  variables,
  documentContent,
  toolbarButtons,
  editorResizeHandler,
  contentChangeHandler,
}) => {
  const editorId = `froala_editor_${gridBlockId}`;
  const editorRef = useRef<FroalaEditor>(null);
  const debounceDelay = 100;
  useTributeOnFroalaInit({ variables, editorRef: editorRef });
  const { usedVariables, handleUsedVariablesParsing } = useUsedVariables();
  const { handleUsedVariables } = useContext(UsedVariablesContext);
  const placeholderTextConfig = {
    placeholderText: '',
  };

  useEffect(() => {
    handleUsedVariables(gridBlockId, usedVariables);
  }, [usedVariables]);

  const defaultConfig = {
    ...editorConfig,
    ...configOptions,
    ...placeholderTextConfig,
    ...{
      autofocus: true,
    },
  };

  if (toolbarButtons) {
    defaultConfig['toolbarButtons'] = toolbarButtons.current;
  }

  // Debounce function to limit the frequency of function calls
  const debounce = <T extends unknown[]>(func: (...args: T) => void, delay: number) => {
    let timeout: NodeJS.Timeout;
    return function (this: unknown, ...args: T) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    const editorResizeHandlerDebounced = debounce((editorId: string, rect: DOMRectReadOnly) => {
      if (editorResizeHandler) {
        editorResizeHandler(editorId, rect);
      }
    }, debounceDelay);

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      editorResizeHandlerDebounced(gridBlockId, entry.contentRect);
    });

    const froalaEditorElement = document.querySelector('.' + editorId);

    // Attach the observer to the FroalaEditor element
    if (froalaEditorElement) {
      observer.observe(froalaEditorElement);
    }

    return () => {
      observer.disconnect();
    };
  }, [gridBlockId, editorRef.current, editorResizeHandler]);

  const modelChangeHandler = (documentContent) => {
    handleUsedVariablesParsing(documentContent);
    if (contentChangeHandler) {
      contentChangeHandler(gridBlockId, documentContent);
    }
  };

  return (
    <div className={'froala_editor_' + gridBlockId}>
      <FroalaEditor
        tag="textarea"
        config={{ ...defaultConfig }}
        ref={editorRef}
        onModelChange={modelChangeHandler}
        model={documentContent || ''}
      />
    </div>
  );
};

export default FroalaEditorWrapper;
