import { ApiPlanRepository } from '../services/repositories/implementations/ApiPlanRepository';
import { usePlanApiData } from './usePlanApiData';

type useIsAccountPausableParams = {
  accountId: number;
  enabled?: boolean;
};

export function useIsAccountPausable({ accountId, enabled = true }: useIsAccountPausableParams) {
  const apiPlanRepository = new ApiPlanRepository();
  const { planData, isError } = usePlanApiData({
    planRepository: apiPlanRepository,
    accountId,
    enabled: !!enabled,
  });

  if (isError || typeof planData === 'undefined') return false;
  const { pausable } = planData;

  return pausable;
}
