import React, { useContext } from 'react';
import { Rnd } from 'react-rnd';
import * as Popover from '@radix-ui/react-popover';
import type { RndResizeCallback, RndDragCallback } from 'react-rnd';
import { SignaturesContext } from 'providers/SignaturesProvider';
import { dimensions as defaultDimensions, position as defaultPositions, signatureZindex } from './constants';
import { Dimensions, Position } from './interfaces';
import { getSignatureColor, SignatureBaseButton } from '../sidepanel/signatures/signature-base-button';
import { useTranslation } from 'react-i18next';
import './styles.less';
import { SignatureDetail } from './signature-detail';
import { AssignedSignee, SignatureStatus, SignatureType } from 'services/repositories/interfaces/SignatureRepository';
import { Block } from '../GridDndEditor/models/block.model';

export interface UnSignedSignatureProps {
  signatureId: string;
  assignedSignee?: AssignedSignee | null;
  type?: SignatureType;
  handleClick?: () => void;
  position?: Position;
  dimensions?: Dimensions;
  enableResizing?: boolean;
  disableDragging?: boolean;
  bounds?: string;
  onDrag?: (e, block: Block) => void;
}

const UnSignedSignature: React.FunctionComponent<UnSignedSignatureProps> = ({
  signatureId,
  assignedSignee = null,
  type = SignatureType.Signature,
  position = defaultPositions,
  dimensions = defaultDimensions,
  handleClick,
  enableResizing = true,
  disableDragging = false,
  bounds = '.fr-box.fr-basic',
  onDrag,
}) => {
  const { t } = useTranslation();
  const { handleSignaturePropertyUpdate, handleSignatureRemoval, documentId } = useContext(SignaturesContext);
  const signee = assignedSignee;
  const handleSignatureDragEvent: RndDragCallback = (_, data) => {
    handleSignaturePropertyUpdate({
      signatureBoxId: signatureId,
      type,
      properties: {
        position: {
          x: data.x,
          y: data.y,
        },
        dimensions: {
          width: parseInt(data.node.style.width, 10),
          height: parseInt(data.node.style.height, 10),
        },
      },
      assignedSignee,
      status: SignatureStatus.UnSigned,
      signatureEvent: null,
      documentId,
    });
  };

  const handleSignatureResizeEvent: RndResizeCallback = (_, __, ref, ___, position) => {
    handleSignaturePropertyUpdate({
      signatureBoxId: signatureId,
      type,
      status: SignatureStatus.UnSigned,
      properties: {
        position: {
          x: position.x,
          y: position.y,
        },
        dimensions: {
          height: parseInt(ref.style.height, 10),
          width: parseInt(ref.style.width, 10),
        },
      },
      assignedSignee,
      signatureEvent: null,
      documentId,
    });
  };

  const isDragAndResizeDisabled = disableDragging || !enableResizing;
  const onSignatureDrag = (e, data) => {
    return onDrag?.(e, {
      height: dimensions.height,
      width: dimensions.width,
      id: signatureId,
      x: data.x,
      y: data.y,
    } as Block);
  };

  return (
    <Rnd
      className="unsigned_signature"
      style={{ zIndex: signatureZindex }}
      bounds={bounds}
      resizeHandleWrapperClass="selected"
      default={{
        ...position,
        ...dimensions,
      }}
      disableDragging={disableDragging}
      enableResizing={enableResizing}
      data-resizable={enableResizing}
      lockAspectRatio
      minHeight={defaultDimensions.height}
      minWidth={defaultDimensions.width}
      onClick={handleClick}
      onDragStop={handleSignatureDragEvent}
      onResizeStop={handleSignatureResizeEvent}
      onDrag={onSignatureDrag}
    >
      {isDragAndResizeDisabled ? (
        <SignatureBaseButton color={getSignatureColor(signee?.email)} data-signature-id={signatureId}>
          {t('signature')}
        </SignatureBaseButton>
      ) : (
        <Popover.Root>
          <Popover.Trigger asChild>
            <SignatureBaseButton color={getSignatureColor(signee?.email)} data-signature-id={signatureId}>
              {t('signature')}
            </SignatureBaseButton>
          </Popover.Trigger>
          <Popover.Content side="top" className="signature__tooltip" sideOffset={5}>
            <SignatureDetail
              title={signee ? `${signee.firstName} ${signee.lastName}` : t('unassigned')}
              label={signee ? `${signee.firstName} ${signee.lastName?.[0]}.` : t('unassigned')}
              onDelete={() => {
                handleSignatureRemoval(signatureId);
              }}
            />
          </Popover.Content>
        </Popover.Root>
      )}
    </Rnd>
  );
};

export default UnSignedSignature;
