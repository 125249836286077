import { useState, useCallback } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';

export type EditorRefType = {
  current: FroalaEditor | null;
};

function useEditorRef() {
  const [editorRef, setEditorRef] = useState<EditorRefType>({ current: null });

  const setRef = useCallback((node) => {
    setEditorRef({ current: node });
  }, []);

  return { editorRef, setRef };
}

export default useEditorRef;
