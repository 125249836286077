export const FeatureFlags = {
  newShareExperience: 'react-share-experience',
  sso: 'single-sign-on',
  crmDataFlexibility: 'crm-data-flexibility',
  editorV3: 'editor-v3',
  documentSettings: 'document-settings',
  editorV3Variables: 'editor-v3-variables',
  multiaccounts: 'account-multi-access',
  snapToGrid: 'editor-v3-snap-to-grid',
  centralizedUndoRedo: 'centralized-undo-redo',
};
