import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Block } from 'components/editor/GridDndEditor/models/block.model';
import { GridBlockType } from 'components/editor/shared/gridBlockType';

export interface GridBlockContent {
  id: string;
  content: string;
  blockConfig: Block;
  type: GridBlockType;
}

export interface Document {
  id: string;
  gridBlocks: GridBlockContent[];
}

export interface GridBlockState {
  documents: Document[];
}

const initialState: GridBlockState = {
  documents: [],
};

const gridBlockSlice = createSlice({
  name: 'editor-grid-block',
  initialState,
  reducers: {
    addGridBlockState(
      state,
      action: PayloadAction<{ documentId: string; gridBlockId: string; content: string; blockConfig: Block; blockType: GridBlockType }>
    ) {
      const { documentId, gridBlockId, content, blockConfig, blockType } = action.payload;
      const documentIndex = state.documents.findIndex((document) => document.id === documentId);
      if (documentIndex == -1) {
        // Create a new document entry with a gridBlock
        state.documents.push({
          id: documentId,
          gridBlocks: [
            {
              id: gridBlockId,
              content: content,
              blockConfig,
              type: blockType,
            },
          ],
        });
      } else {
        const document = state.documents[documentIndex];
        // Add a new grid block within the existing document's gridBlocks
        const updatedGridBlocks = [
          ...document.gridBlocks,
          {
            id: gridBlockId,
            content: content || '',
            blockConfig,
            type: blockType,
          },
        ];

        state.documents = [
          ...state.documents.slice(0, documentIndex),
          {
            ...document,
            gridBlocks: updatedGridBlocks,
          },
          ...state.documents.slice(documentIndex + 1),
        ];
      }
    },
    updateGridBlockState(state, action: PayloadAction<{ documentId: string; gridBlockId: string; content: string }>) {
      const { documentId, gridBlockId, content } = action.payload;
      const documentIndex = state.documents.findIndex((document) => document.id === documentId);

      if (documentIndex !== -1) {
        const document = state.documents[documentIndex];
        const gridBlockIndex = document.gridBlocks.findIndex((gridBlock) => gridBlock.id === gridBlockId);

        if (gridBlockIndex !== -1) {
          // Update existing grid block
          const updatedGridBlocks = [
            ...document.gridBlocks.slice(0, gridBlockIndex),
            {
              ...document.gridBlocks[gridBlockIndex],
              content,
            },
            ...document.gridBlocks.slice(gridBlockIndex + 1),
          ];

          state.documents = [
            ...state.documents.slice(0, documentIndex),
            {
              ...document,
              gridBlocks: updatedGridBlocks,
            },
            ...state.documents.slice(documentIndex + 1),
          ];
        } else {
          throw new Error('Update content called, but grid doesnt exist');
        }
      } else {
        throw new Error('Update content called, but Document doesnt exist');
      }
    },
    updateGridPositionConfig(state, action: PayloadAction<{ documentId: string; gridBlockId: string; x: number; y: number }>) {
      const { documentId, gridBlockId, x, y } = action.payload;
      const documentIndex = state.documents.findIndex((document) => document.id === documentId);
      const document = state.documents[documentIndex];
      const gridBlockIndex = document.gridBlocks.findIndex((gridBlock) => gridBlock.id === gridBlockId);

      const updatedGridWithConfigChanges = {
        ...document.gridBlocks[gridBlockIndex],
        blockConfig: {
          ...document.gridBlocks[gridBlockIndex].blockConfig,
          x,
          y,
        },
      };
      const updatedGridBlocks = [
        ...document.gridBlocks.slice(0, gridBlockIndex),
        updatedGridWithConfigChanges,
        ...document.gridBlocks.slice(gridBlockIndex + 1),
      ];

      state.documents = [
        ...state.documents.slice(0, documentIndex),
        {
          ...document,
          gridBlocks: updatedGridBlocks,
        },
        ...state.documents.slice(documentIndex + 1),
      ];
    },
    updateGridDimensionConfig(state, action: PayloadAction<{ documentId: string; gridBlockId: string; width: number; height: number }>) {
      const { documentId, gridBlockId, width, height } = action.payload;
      const documentIndex = state.documents.findIndex((document) => document.id === documentId);
      const document = state.documents[documentIndex];
      const gridBlockIndex = document.gridBlocks.findIndex((gridBlock) => gridBlock.id === gridBlockId);

      const updatedGridWithConfig = {
        ...document.gridBlocks[gridBlockIndex],
        blockConfig: {
          ...document.gridBlocks[gridBlockIndex].blockConfig,
          width,
          height,
        },
      };
      const updatedGridBlocks = [
        ...document.gridBlocks.slice(0, gridBlockIndex),
        updatedGridWithConfig,
        ...document.gridBlocks.slice(gridBlockIndex + 1),
      ];

      const updatedDocumentWithGridConfigChanges = {
        ...document,
        gridBlocks: updatedGridBlocks,
      };
      state.documents = [
        ...state.documents.slice(0, documentIndex),
        updatedDocumentWithGridConfigChanges,
        ...state.documents.slice(documentIndex + 1),
      ];
    },
    setInitialState(state, action: PayloadAction<{ documentId: string; gridBlocks: GridBlockContent[] }>) {
      const { documentId, gridBlocks } = action.payload;
      const documentIndex = state.documents.findIndex((document) => document.id === documentId);
      if (documentIndex === -1) {
        state.documents.push({
          id: documentId,
          gridBlocks: gridBlocks,
        });
      } else {
        state.documents[documentIndex].gridBlocks = gridBlocks;
      }
    },
  },
});

export const { addGridBlockState, updateGridBlockState, updateGridPositionConfig, updateGridDimensionConfig, setInitialState } =
  gridBlockSlice.actions;
export const gridBlockReducer = gridBlockSlice.reducer;
