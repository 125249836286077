import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { EvaluateFeatureFlags } from 'routing/EvaluateFeatureFlags';
import { FeatureFlags } from 'utils/featureFlags';
import { Layout } from 'antd';
import { Editor } from '../';
import { SocketClientInterface } from 'services/socket/SocketClient';
import { SaveStatus, SignaturesContext } from 'providers/SignaturesProvider';
import { VariablesGroup } from 'services/entities/VariablesEntity';
import { formatOptions, options } from '../variables/options';
import Tribute from 'tributejs';
import FroalaEditor from 'react-froala-wysiwyg';
import { UsedVariable } from '../sidepanel/variables/interfaces';
import { EditorRefType } from 'hooks/useEditorRef';
import { PageBreaks } from '../pagebreaks';
import { useHandlePageBreaks } from 'hooks/useHandlePageBreaks';
import { useUsedVariables } from 'hooks/useUsedVariables';
import { GridDndEditor } from '../GridDndEditor';
import {
  GridBlockAddHandler,
  GridBlockChangeHandler,
  GridBlockDimensionChangeHandler,
  GridBlockPositionChangeHandler,
} from '../GridDndEditor/GridDndEditor';
import { useDispatch, useSelector } from 'react-redux';
import {
  addGridBlockState,
  updateGridBlockState,
  updateGridDimensionConfig,
  updateGridPositionConfig,
} from '../grid/reduxStore/editorSlice';
import { RootState } from '../grid/reduxStore/store';
import { addGridBlockWithSocket, saveConfigWithSocket, saveContentWithSocket } from '../grid/reduxStore/saveReducers';
import { Events } from '../../../services/socket/SocketEvents';
import { AnyAction } from '@reduxjs/toolkit';
import { useCurrentUser } from 'providers/UserProvider';
import { AddBlock } from '../GridDndEditor/models/addBlock.model';
import { GridBlockType } from '../shared/gridBlockType';

const { Content } = Layout;

interface EditorContentProps {
  documentId: string;
  socketClient: SocketClientInterface;
  variables?: VariablesGroup;
  editorRef: EditorRefType;
  setRef: (node: FroalaEditor | null) => void;
  setUsedVariables: (usedVariables: UsedVariable[]) => void;
  editorConfig: any;
  setContentSaveStatus: React.Dispatch<React.SetStateAction<SaveStatus>>;
  initialDocContent: string | undefined;
  gridRef: React.RefObject<HTMLDivElement>;
  toolbarButtons?: any;
}

const EDITING_AREA_ID = 'editing-area';

const EditorContent: React.FC<EditorContentProps> = ({
  documentId,
  socketClient,
  variables,
  editorRef,
  setRef,
  setUsedVariables,
  editorConfig,
  setContentSaveStatus,
  initialDocContent,
  gridRef,
}) => {
  const { getSignaturesList } = useContext(SignaturesContext);
  const pageRef = useRef<HTMLDivElement>(null);
  const { pageBreaks } = useHandlePageBreaks(pageRef);
  const { usedVariables, handleUsedVariablesParsing } = useUsedVariables();
  const dispatch = useDispatch();
  const gridBlocks = useSelector((state: RootState) => {
    const document = state.gridBlocks.documents.find((doc) => doc.id === documentId);
    return document ? document.gridBlocks : [];
  });
  const { data } = useCurrentUser();
  const userId = data.id;

  useEffect(() => {
    setUsedVariables(usedVariables);
  }, [usedVariables]);

  useEffect(() => {
    if (variables === undefined || editorRef.current?.getEditor == null) return;
    const formattedOptions = { values: formatOptions(variables) };
    const tribute = new Tribute(Object.assign(options, formattedOptions));
    const { el } = (editorRef.current as FroalaEditor).getEditor();
    tribute.attach(el);
  }, [variables, editorRef]);

  const gridBlockAddHandler: GridBlockAddHandler = (documentContent: string, blockConfig: AddBlock) => {
    dispatch(
      addGridBlockWithSocket({
        documentId,
        userId,
        content: documentContent,
        blockConfig,
        socketClient,
        type: GridBlockType.TEXT,
        callback: (response) => {
          const gridBlockId = JSON.parse(response).content.gridId;
          const updatedConfig = {
            ...blockConfig,
            id: gridBlockId,
          };
          const payload = {
            documentId: documentId,
            content: documentContent,
            blockConfig: updatedConfig,
            gridBlockId: gridBlockId,
            blockType: GridBlockType.TEXT,
          };
          dispatch(addGridBlockState(payload));
        },
      }) as unknown as AnyAction
    );
  };

  const gridBlockChangeHandler: GridBlockChangeHandler = (gridBlockId: string, documentContent: string) => {
    const payload = {
      documentId: documentId,
      gridBlockId: gridBlockId,
      content: documentContent,
    };

    dispatch(updateGridBlockState(payload));
    dispatch(
      saveContentWithSocket({
        gridBlockId: gridBlockId,
        content: documentContent,
        userId: userId,
        documentId: documentId,
        socketClient: socketClient,
      }) as unknown as AnyAction
    );
  };
  const gridBlockPositionChangeHandler: GridBlockPositionChangeHandler = (gridBlockId, x, y) => {
    const payload = {
      documentId: documentId,
      gridBlockId: gridBlockId,
      x,
      y,
    };

    dispatch(updateGridPositionConfig(payload));
    dispatch(
      saveConfigWithSocket({
        documentId,
        gridBlockId,
        x,
        y,
        userId: userId,
        eventType: Events.GRID_POSITION_CHANGED,
        socketClient,
      }) as unknown as AnyAction
    );
  };

  const gridBlockDimensionChangeHandler: GridBlockDimensionChangeHandler = (gridBlockId, width, height) => {
    const payload = {
      documentId: documentId,
      gridBlockId: gridBlockId,
      width,
      height,
    };

    dispatch(updateGridDimensionConfig(payload));
    dispatch(
      saveConfigWithSocket({
        documentId,
        gridBlockId,
        width,
        height,
        userId: userId,
        eventType: Events.GRID_DIMENSION_CHANGED,
        socketClient,
      }) as unknown as AnyAction
    );
  };

  return (
    <Content ref={pageRef}>
      <EvaluateFeatureFlags
        flags={[FeatureFlags.snapToGrid]}
        onConditionFailed={() => (
          <Fragment>
            <div id={EDITING_AREA_ID}>
              <Editor
                ref={setRef}
                documentId={documentId}
                socketClient={socketClient}
                handleDocumentContentStatus={setContentSaveStatus}
                handleUsedVariablesParsing={handleUsedVariablesParsing}
                configOptions={editorConfig}
                initialDocContent={initialDocContent}
              />
              {getSignaturesList(false)}
            </div>
            <PageBreaks pageBreaks={pageBreaks} />
          </Fragment>
        )}
      >
        <GridDndEditor
          documentId={documentId}
          gridBlocks={gridBlocks}
          gridRef={gridRef}
          configOptions={editorConfig}
          variables={variables}
          gridBlockAddHandler={gridBlockAddHandler}
          gridBlockChangeHandler={gridBlockChangeHandler}
          gridBlockPositionChangeHandler={gridBlockPositionChangeHandler}
          gridBlockDimensionChangeHandler={gridBlockDimensionChangeHandler}
        />
        <PageBreaks pageBreaks={pageBreaks} />
      </EvaluateFeatureFlags>
    </Content>
  );
};

export default EditorContent;
