import { useEffect, useState } from 'react';
import { useSignaturesByDocumentId } from './useSignaturesByDocumentIdQuery';
import { SignatureBox } from 'services/repositories/interfaces/SignatureRepository';
import { getSignaturesMaxHeight } from '../components/editor/sidepanel/signatures/signature-helper';

export const usePreviewSignatures = (documentId: string) => {
  const [signatures, setSignatures] = useState<SignatureBox[]>([]);
  const [maxSignatureHeight, setMaxSignatureHeight] = useState<number>(0);

  const { data } = useSignaturesByDocumentId(documentId);

  useEffect(() => {
    const maxHeight = getSignaturesMaxHeight(signatures);
    setMaxSignatureHeight(maxHeight);
  }, [signatures]);

  useEffect(() => {
    if (!data) return;

    setSignatures(data.signatures);
  }, [data, documentId]);

  return { signatures, setSignatures, maxSignatureHeight };
};
