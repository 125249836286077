import { forwardRef, ReactNode } from 'react';
import {
  GridBlockAddHandler,
  GridBlockChangeHandler,
  GridBlockDimensionChangeHandler,
  GridBlockPositionChangeHandler,
} from './GridDndEditor';
import { Rnd } from 'react-rnd';
import Draggable from 'react-draggable';
import { VariablesGroup } from '../../../services/entities/VariablesEntity';
import { GridBlockContent } from '../grid/reduxStore/editorSlice';
import { Block } from './models/block.model';

export interface GridBlockProps {
  configOptions?: object | undefined;
  variables?: VariablesGroup;
  gridBlock: GridBlockContent;
  children?: ReactNode;
  gridSystemInPx: number;
  gridRef?: React.MutableRefObject<HTMLDivElement | null>;
  gridBlockAddHandler?: GridBlockAddHandler;
  gridBlockChangeHandler?: GridBlockChangeHandler;
  gridBlockPositionChangeHandler?: GridBlockPositionChangeHandler;
  gridBlockDimensionChangeHandler?: GridBlockDimensionChangeHandler;
  onDragStop?: (e, data, item: Block) => void;
  onResizeStop?: (e, direction, ref, delta, item: Block) => void;
}

export const GridBlock = forwardRef<HTMLDivElement, GridBlockProps>(
  ({ gridBlock: block, children, gridSystemInPx, onDragStop, onResizeStop }) => {
    return (
      <div>
        <Draggable
          defaultClassName="editor__page__draggable"
          key={block.id}
          handle=".editor__page__grid-drag-handle"
          position={{ x: block.blockConfig.x, y: block.blockConfig.y }}
          grid={[gridSystemInPx, gridSystemInPx]}
          scale={1}
          onStop={(e, data) => {
            if (onDragStop) onDragStop(e, data, block.blockConfig);
          }}
        >
          <Rnd
            size={{
              width: block.blockConfig.width || gridSystemInPx,
              height: block.blockConfig.height || gridSystemInPx,
            }}
            position={{
              x: block.blockConfig.x,
              y: block.blockConfig.y,
            }}
            minWidth={gridSystemInPx}
            minHeight={gridSystemInPx}
            dragGrid={[gridSystemInPx, gridSystemInPx]}
            resizeGrid={[gridSystemInPx, gridSystemInPx]}
            dragHandleClassName="editor__page__grid-drag-handle"
            resizeHandleClasses={{ bottomRight: 'editor__page__grid-resize-handle' }}
            enableResizing={{ bottomRight: true }}
            onResize={(e, direction, ref, delta) => {
              if (onResizeStop) onResizeStop(e, direction, ref, delta, block.blockConfig);
            }}
            onResizeStop={(e, direction, ref, delta) => {
              if (onResizeStop) onResizeStop(e, direction, ref, delta, block.blockConfig);
            }}
          >
            <div className={`grid_block_${block.id}`}>{children}</div>
          </Rnd>
        </Draggable>
      </div>
    );
  }
);

GridBlock.displayName = 'GridBlock';
