import Froala from 'froala-editor';
import { UndoRedoManager, ContentChangeCommand } from './UndoRedoManager';

const undoRedoManager = new UndoRedoManager();
type InitFunction = () => void;
type UndoFunction = () => void;
type RedoFunction = () => void;
type canUndoFunction = () => boolean;
type canRedoFunction = () => boolean;
interface undoPluginMethods {
  _init: InitFunction;
  undo: UndoFunction;
  redo: RedoFunction;
  canUndo: canUndoFunction;
  canRedo: canRedoFunction;
}

export const undoPlugin = function (editor: Froala): undoPluginMethods {
  function _init(): void {
    editor.events.on('commands.before', function (cmd: string) {
      switch (cmd) {
        case 'centralizedUndo':
          undoRedoManager.setIsUndoCalled(true);
          break;
        case 'centralizedRedo':
          undoRedoManager.setIsRedoCalled(true);
          break;
      }
    });

    editor.events.on('contentChanged', function () {
      if (!undoRedoManager.isUndoOrRedoCalled()) {
        undoRedoManager.executeCommand(new ContentChangeCommand(editor));
        undoRedoManager.resetRedoStack();
      }
      undoRedoManager.resetUndoRedoCalledFlag();
      //Only for QAs to test
      undoRedoManager.displayUndoRedo();
    });
  }

  function undo(): void {
    undoRedoManager.undoCommand();
    //Only for QAs to test
    undoRedoManager.displayUndoRedo();
  }

  function redo(): void {
    undoRedoManager.redoCommand();
    //Only for QAs to test
    undoRedoManager.displayUndoRedo();
  }

  function canUndo(): boolean {
    return undoRedoManager.isUndoStackNotEmpty();
  }

  function canRedo(): boolean {
    return undoRedoManager.isRedoStackNotEmpty();
  }

  return {
    _init: _init,
    undo: undo,
    redo: redo,
    canUndo: canUndo,
    canRedo: canRedo,
  };
};
