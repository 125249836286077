import React, { useCallback, useContext } from 'react';
import { SignaturesContext, SignatureSocketWriteOperationPayload } from 'providers/SignaturesProvider';
import { dimensions, draggedOffset, position } from '../../signatures/constants';
import { isPointInRect } from 'utils/cursorPositions';
import { getSignatureColor, SignatureBaseButton } from './signature-base-button';
import { useTranslation } from 'react-i18next';
import {
  AssignedSignee,
  SignatureStatus,
  SignatureType,
  UnAssignedUnSignedSignatureBox,
} from 'services/repositories/interfaces/SignatureRepository';

export interface SignatureButtonProps {
  signee?: AssignedSignee | null;
  editorRef?: any;
  gridRef?: React.RefObject<HTMLElement>;
}

export const defaultSignature: UnAssignedUnSignedSignatureBox = {
  signatureBoxId: '',
  type: SignatureType.Signature,
  status: SignatureStatus.UnSigned,
  properties: {
    position,
    dimensions,
  },
  signatureEvent: null,
  assignedSignee: null,
};

const SignatureButton: React.FC<SignatureButtonProps> = ({ signee, editorRef, gridRef }) => {
  const { t } = useTranslation();
  const { handleSignatureInsert, documentId } = useContext(SignaturesContext);
  const handleDragEnd = useCallback(
    (e: React.DragEvent) => {
      const editorElement = gridRef ? gridRef?.current : editorRef?.current?.editor?.el;

      if (!editorElement) return;

      const editorArea = editorElement?.getBoundingClientRect();

      if (!isPointInRect(editorArea, { x: e.clientX, y: e.clientY })) return;

      const draggedPosition = {
        x: e.clientX - draggedOffset.x,
        y: e.clientY - draggedOffset.y + window.scrollY,
      };

      const signatureProperties = { ...defaultSignature.properties, ...{ position: draggedPosition } };

      let payload: SignatureSocketWriteOperationPayload = {
        ...defaultSignature,
        signatureBoxId: '',
        properties: signatureProperties,
        documentId,
      };

      if (signee != null) {
        payload = {
          ...payload,
          assignedSignee: signee,
        };
      }

      handleSignatureInsert(payload);
    },
    [editorRef.current, gridRef, signee?.email, documentId]
  );
  return (
    <SignatureBaseButton
      style={{
        width: dimensions.width,
        height: dimensions.height,
      }}
      draggable={true}
      color={getSignatureColor(signee?.email)}
      onDragEnd={handleDragEnd}
      title={signee?.email ?? t('unassigned')}
    >
      {t('signature')}
    </SignatureBaseButton>
  );
};

export default SignatureButton;
