import Axios, { AxiosResponse } from 'axios';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { HttpCodeStatus } from '../query/HttpCodeStatus';
import { logoutUser } from 'services/authorization/handleLogout';
import { AuthCookieService } from '../cookie';
import { refreshTokenFlow } from 'services/backgroundrefreshtoken';
import { isAccountExpiredOrSuspended } from './utils';

const defaultAxiosRequestConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
};

const requestInterceptor = async (config: AxiosRequestConfig) => {
  const Authorization = `Bearer ${AuthCookieService.getAccessToken()}`;
  const headers = {
    ...config.headers,
    Authorization,
  };
  config.headers = headers;
  return config;
};

type RequestConfig = AxiosRequestConfig & { _retry?: boolean };
const onRejected = async (error: AxiosError<undefined | { message?: string }>) => {
  const response = error.response;
  const originalRequestConfig: RequestConfig = error.config;
  if (response) {
    if (isAccountExpiredOrSuspended(response)) {
      logoutUser();
    } else if (response.status === HttpCodeStatus.UNAUTHORIZED) {
      if (originalRequestConfig._retry !== true) {
        originalRequestConfig._retry = true;
        return refreshTokenFlow().then(() => {
          return axios(originalRequestConfig);
        });
      } else {
        logoutUser();
      }
    }
  }

  return Promise.reject(error);
};

const axios = Axios.create(defaultAxiosRequestConfig);
axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(undefined, onRejected);

export const apiClient = {
  async get<ApiResponse = unknown>(url: string, options?: Pick<AxiosRequestConfig, 'params'>): Promise<ApiResponse> {
    const response = await axios.get(url, options);
    return response.data;
  },
  async post<ApiResponse = unknown>(url: string, data: unknown): Promise<ApiResponse> {
    try {
      const response = await axios.post<ApiResponse>(url, data);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      throw error.response?.data;
    }
  },
  async put<ApiResponse = unknown>(url: string, data?: unknown): Promise<ApiResponse> {
    const response = await axios.put(url, data);
    return response.data;
  },
  async patch<ApiResponse = unknown, TData = unknown>(url: string, data?: TData): Promise<ApiResponse> {
    const response = await axios.patch<ApiResponse, AxiosResponse, TData>(url, data);
    return response.data;
  },
  async delete<ApiResponse = unknown>(url: string): Promise<ApiResponse> {
    const response = await axios.delete(url);
    return response.data;
  },
  async postForm<ApiResponse = unknown>(url: string, data: unknown): Promise<ApiResponse> {
    try {
      const response = await axios.postForm<ApiResponse>(url, data);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      throw error.response?.data;
    }
  },
};
