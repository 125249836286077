interface featureGroupProps {
  brand_customization?: planFeature[];
  content_authoring?: planFeature[];
  visibility?: planFeature[];
  process_control?: planFeature[];
  success?: planFeature[];
}

type planFeature = {
  name: string;
  free?: boolean;
  team?: boolean;
  business?: boolean;
  isNonInclusive: boolean;
};

const freeTopFeatures = ['settings.plan.free.feature_1', 'settings.plan.free.feature_2', 'settings.plan.free.feature_3'];

const teamTopFeatures = ['settings.plan.team.feature_1', 'settings.plan.team.feature_2', 'settings.plan.team.feature_3'];

const businessTopFeatures = ['settings.plan.business.feature_1', 'settings.plan.business.feature_2', 'settings.plan.business.feature_3'];

const allPlanFeatures = {
  brand_customization: [
    {
      name: 'settings.plan.features.content_library',
      free: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.unlimited_templates',
      free: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.custom_domain',
      free: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.embed_images_and_videos',
      free: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
  ],
  content_authoring: [
    {
      name: 'settings.plan.features.online_signatures',
      free: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.interactive_quoting',
      free: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.client_input_forms',
      free: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
  ],
  visibility: [
    {
      name: 'settings.plan.features.notifications_metrics',
      free: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.pdf_export',
      free: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.reports',
      free: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
  ],
  process_control: [
    {
      name: 'settings.plan.features.custom_fields_variables',
      free: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.payments',
      free: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.standard_integrations',
      free: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.auto_reminders',
      free: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.roles_and_permissions',
      free: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.approval_workflows',
      free: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.salesforce_integration',
      free: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.workspaces',
      free: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
  ],
  success: [
    {
      name: 'settings.plan.features.email_chat_support',
      free: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.phone_zoom_support',
      free: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.success_manager',
      free: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.onboarding_services',
      free: false,
      team: true,
      business: true,
      isNonInclusive: true,
    },
    {
      name: 'settings.plan.features.premium_integration_support',
      free: false,
      team: false,
      business: true,
      isNonInclusive: true,
    },
  ],
} as featureGroupProps;

export { freeTopFeatures, teamTopFeatures, businessTopFeatures, allPlanFeatures };
