import { Heading, Icon, openNotification } from 'components';
import { Snackbar } from 'components/snackbar';
import { PipelineList } from '../../list';
import { useDocumentListQuery } from 'hooks/useDocumentListQuery';
import { restoreDocumentFromTrash } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { moveDocumentToTrash } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { EmptyTrashModal } from './emptyTrashModal';
import { deleteAllDocuments } from 'services/proposal/repositories/implementations/ApiProposalRepository';

export function ListDocumentsTrash() {
  const { t } = useTranslation();

  const { documentList, isLoadingDocumentList, setDocumentList } = useDocumentListQuery({ filterTrash: true });
  const [modalEmptyTrashVisible, setModalEmptyTrashVisible] = useState(false);

  const handleEmptyTrashAction = async () => {
    try {
      await deleteAllDocuments();
      setDocumentList([]);
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('pipeline.error.delete_error_msg'),
        description: t('pipeline.error.delete_error_description'),
        placement: 'top',
      });
    }
  };

  const handleRestoreAction = async (documentId: string) => {
    await restoreDocumentFromTrash(documentId);
  };

  const handleRestoreUndo = async (documentId: string) => {
    await moveDocumentToTrash(documentId);
  };

  const snackBarActionProp = {
    text: t('pipeline.tabs.trash_document_list.empty_action'),
    onClick: () => setModalEmptyTrashVisible(true),
  };

  return (
    <>
      <header className="pipeline__list-header">
        <Heading level={1}>{t('pipeline.tabs.trash_document_list.title')}</Heading>
      </header>
      <Snackbar
        message={t('pipeline.tabs.trash_document_list.auto_empty_message')}
        action={documentList.length ? snackBarActionProp : undefined}
      />
      <PipelineList
        type="Trash"
        documentList={documentList}
        setDocumentList={setDocumentList}
        isLoading={isLoadingDocumentList}
        listEmptyMessage={t('pipeline.tabs.trash_document_list.list_empty')}
        action={{
          message: t('pipeline.tabs.trash_document_list.action_message'),
          tooltipText: t('pipeline.tabs.trash_document_list.action_tooltip'),
          onAction: handleRestoreAction,
          onUndo: handleRestoreUndo,
          icon: <Icon name="IcoRestoreDocument" />,
        }}
      />
      <EmptyTrashModal onConfirm={handleEmptyTrashAction} visible={modalEmptyTrashVisible} setVisible={setModalEmptyTrashVisible} />
    </>
  );
}
