import { useTranslation } from 'react-i18next';
import { WorkspaceAccount } from '../workspace-account';
import { WorkspaceSwitchableAccount } from 'services/settings/entities/Workspaces';
import './styles.less';

type Props = {
  accounts: WorkspaceSwitchableAccount[];
};

export const LinkedAccounts = ({ accounts }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div data-testId="workspaces-linked-accounts">
      {accounts.length < 1 && <div className="workspaces-linked-accounts__no-data">{t('settings.workspaces_switcher.no_data_label')}</div>}
      {accounts.map((account, key) => (
        <WorkspaceAccount account={account} key={key} />
      ))}
    </div>
  );
};
