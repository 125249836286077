import { useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { useTranslation } from 'react-i18next';
import { Heading } from 'components/typography';
import { loadSignatureOptions, SignatureSelect } from 'components/editor/signatures/signature-select';
import type { SignatureSelectOption } from 'components/editor/signatures/signature-select';
import { useCurrentUser } from 'providers/UserProvider';

import SignatureButton from './signature-button';
import './styles.less';
import { EvaluateFeatureFlags } from '../../../../routing/EvaluateFeatureFlags';
import { FeatureFlags } from '../../../../utils/featureFlags';
import { DraggableSignatureButton } from './draggable-signature-button';
interface Props {
  editorRef: React.Ref<FroalaEditor>;
  gridRef?: React.RefObject<HTMLElement>;
}

export function Signatures({ editorRef, gridRef }: Props) {
  const { t } = useTranslation();
  const {
    data: { accountId },
  } = useCurrentUser();
  const [selectedSignee, setSelectedSignee] = useState<SignatureSelectOption | null | undefined>(null);

  return (
    <>
      <Heading level={5}>Signatures</Heading>
      <div className="signature__container">
        <div>
          <div className="field_label">{t('editor.signature.for')}</div>
          <SignatureSelect
            value={selectedSignee}
            onChange={(newValue) => setSelectedSignee(newValue)}
            loadOptions={loadSignatureOptions(accountId)}
            placeholder={t('editor.signature.search_placeholder')}
          />
        </div>
        <EvaluateFeatureFlags
          flags={[FeatureFlags.snapToGrid]}
          onConditionFailed={() => <SignatureButton signee={selectedSignee} editorRef={editorRef} gridRef={gridRef} />}
        >
          <DraggableSignatureButton signee={selectedSignee} />
        </EvaluateFeatureFlags>
      </div>
    </>
  );
}
