export const URLS = {
  document: '/editor',
  preview: '/preview',
  editDocument: function (documentId: string) {
    return `${this.document}/${documentId}`;
  },
  previewDocument: function (documentId: string) {
    return `${this.preview}/${documentId}`;
  },
  previewGridDocument: function (documentId: string) {
    return `${this.preview}/grid/${documentId}`;
  },
  ssoConfiguration: '/settings/sso',
  pipeline: '/pipeline',
  cinderEditDocument: function (documentId: string) {
    return `/proposal/edit/${documentId}`;
  },
};
