import React from 'react';
import { Modal, Heading } from 'components';
import { useTranslation } from 'react-i18next';
import FeatureTable from './featureTable';
import { seeAllFeaturesState } from './interface';
import { useMediaQuery } from 'hooks/useMediaQuery';

interface SeeAllFeaturesModalProps {
  state: seeAllFeaturesState;
}

const SeeAllFeaturesModal: React.FC<SeeAllFeaturesModalProps> = ({ state }) => {
  const { t } = useTranslation();
  const { isVisible, title, features, planType, onCancel, onConfirm, confirmButtonLabel } = state;
  const { isMobile } = useMediaQuery();

  return (
    <Modal
      isVisible={isVisible}
      handleOk={onConfirm}
      handleCancel={onCancel}
      affirmText={t(confirmButtonLabel)}
      cancelText={t('settings.plan.button_cancel_label')}
      title={title}
      className="feature-table-modal"
    >
      <div className="modal-feature-table">
        {isMobile ? <Heading level={2}>{title}</Heading> : null}
        <FeatureTable features={features} planType={planType} />
      </div>
    </Modal>
  );
};

export default SeeAllFeaturesModal;
