import { Input } from 'antd';
import { Heading } from 'components/typography';
import { Form } from 'components/form';
import { useGetVariables } from 'hooks/useVariables';
import { VariableEntity, VariablesGroup } from 'services/entities/VariablesEntity';
import { EvaluateFeatureFlags } from 'routing/EvaluateFeatureFlags';
import { FeatureFlags } from 'utils/featureFlags';
import { UsedVariable } from './interfaces';
import { useTranslation } from 'react-i18next';

import './styles.less';
import Icon from 'components/icon';

const getVariableItems = (variables: VariableEntity[]) => {
  return variables.map((variable: VariableEntity) => {
    return (
      <Form.Item key={variable?.name} label={variable?.friendlyName}>
        <Input value={variable?.value as string} readOnly></Input>
      </Form.Item>
    );
  });
};

const formatUsedVariables = (usedVariables: UsedVariable[], variables: VariablesGroup): VariableEntity[] => {
  const formattedUsedVariables: VariableEntity[] = [];
  let foundVariable: VariableEntity | undefined;

  for (const usedVariable of usedVariables) {
    if (!usedVariable.type) continue;

    foundVariable = variables[usedVariable.type].find((variable) => variable.name === usedVariable.name);

    if (!foundVariable) continue;

    formattedUsedVariables.push(foundVariable);
  }

  return formattedUsedVariables;
};

const VariablesForm = ({ documentId, usedVariables }: { documentId: string; usedVariables: UsedVariable[] }) => {
  const { data: variables } = useGetVariables(documentId);
  const formattedUsedVariables = formatUsedVariables(usedVariables, variables as VariablesGroup);
  const { t } = useTranslation();

  return (
    <Form layout={'vertical'} className="variables__sidepanel">
      {formattedUsedVariables.length > 0 && (
        <>
          <Heading level={5}>{t('editor.variables.used')}</Heading>
          <Form.Item>{getVariableItems(formattedUsedVariables)}</Form.Item>
        </>
      )}
      <Heading level={5}>{t('editor.variables.user')}</Heading>
      {getVariableItems(variables?.user ?? [])}
      <Heading level={5}>{t('editor.variables.document')}</Heading>
      {getVariableItems(variables?.proposal ?? [])}
      <Heading level={5}>{t('editor.variables.client')}</Heading>
      {getVariableItems(variables?.client ?? [])}
    </Form>
  );
};

interface Props {
  usedVariables: UsedVariable[];
  documentId: string;
}

export function Variables({ usedVariables, documentId }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="variables__sidepanel-helper">
        <Heading level={5}>Variables</Heading>
        <Icon name={'IcoBracketAddPlus'} />
        <Heading level={3}>Add a Variable</Heading>
        <p className="micro-one">{t('editor.variables.helper_text')}</p>
      </div>
      <EvaluateFeatureFlags flags={[FeatureFlags.editorV3Variables]} onConditionFailed={() => null}>
        <VariablesForm documentId={documentId} usedVariables={usedVariables} />
      </EvaluateFeatureFlags>
    </>
  );
}
