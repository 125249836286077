import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Events } from '../../../../services/socket/SocketEvents';
import { defaultCallback, SocketClientInterface } from '../../../../services/socket/SocketClient';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { AddBlock } from 'components/editor/GridDndEditor/models/addBlock.model';

type GridDefaultType = {
  gridId?: string;
  userId: number;
};

export type GridContentChangedType = GridDefaultType & {
  htmlContent: string;
};

export type GridPositionChangedType = GridDefaultType & {
  position: {
    top_px: number;
    left_px: number;
    z_index: number;
  };
};

export type GridDimensionChangedType = GridDefaultType & {
  dimensions: {
    width_px: number;
    height_px: number;
  };
};

export type GridAddType = {
  type: GridBlockType;
} & GridContentChangedType &
  GridPositionChangedType &
  GridDimensionChangedType;

export const addGridBlockWithSocket = createAsyncThunk(
  'editor-grid-block/saveContentWithSocket',
  async (payload: {
    documentId: string;
    content: string;
    blockConfig: AddBlock;
    socketClient: SocketClientInterface;
    userId: number;
    callback: (response) => void;
    type: GridBlockType;
  }) => {
    const { content, userId, blockConfig, callback, socketClient, type } = payload;
    const data: GridAddType = {
      htmlContent: content,
      userId: userId,
      position: { top_px: blockConfig.y, left_px: blockConfig.x, z_index: 0 },
      dimensions: { width_px: blockConfig.width || 0, height_px: blockConfig.height || 0 },
      type: type,
    };
    return new Promise<PayloadAction<string>>((resolve) => {
      socketClient.addGrid(data, (response) => {
        callback(response);
        resolve({ payload: 'Content saved successfully', type: 'editor-grid-block/saveContentWithSocket/fulfilled' });
      });
    });
  }
);

export const saveContentWithSocket = createAsyncThunk(
  'editor-grid-block/saveContentWithSocket',
  async (payload: {
    documentId: string;
    gridBlockId: string;
    content: string;
    socketClient: SocketClientInterface;
    userId: number;
    callback?: (response) => void;
  }) => {
    const { gridBlockId, content, userId, callback = defaultCallback, socketClient } = payload;

    const data: GridContentChangedType = {
      gridId: gridBlockId,
      htmlContent: content,
      userId: userId,
    };
    return new Promise<PayloadAction<string>>((resolve) => {
      socketClient.gridContentChanged(data, (response) => {
        callback(response);
        resolve({ payload: 'Content saved successfully', type: 'editor-grid-block/saveContentWithSocket/fulfilled' });
      });
    });
  }
);

export const saveConfigWithSocket = createAsyncThunk(
  'editor-grid-block/saveConfigWithSocket',
  async (payload: {
    documentId: string;
    gridBlockId: string;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    eventType: Events;
    userId: number;
    socketClient: SocketClientInterface;
    callback?: (response: any) => void;
  }) => {
    const { gridBlockId, userId, x, y, width, height, callback = defaultCallback, eventType, socketClient } = payload;
    if (eventType === Events.GRID_POSITION_CHANGED) {
      const data: GridPositionChangedType = {
        gridId: gridBlockId,
        userId: userId,
        position: { top_px: y || 0, left_px: x || 0, z_index: 0 },
      };
      return new Promise<PayloadAction<string>>((resolve) => {
        socketClient.updatePositionContent(data, (response) => {
          callback(response);
          resolve({ payload: 'Config saved successfully', type: 'editor-grid-block/saveConfigWithSocket/fulfilled' });
        });
      });
    } else {
      const data: GridDimensionChangedType = {
        gridId: gridBlockId,
        userId: userId,
        dimensions: { width_px: width || 0, height_px: height || 0 },
      };
      return new Promise<PayloadAction<string>>((resolve) => {
        socketClient.updateDimensionContent(data, (response) => {
          callback(response);
          resolve({ payload: 'Config saved successfully', type: 'editor-grid-block/saveConfigWithSocket/fulfilled' });
        });
      });
    }
  }
);
