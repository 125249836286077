import React, { useEffect, useState } from 'react';
import './style.less';
import { useImageUploadMutation } from 'hooks/useImageUploadMutation';
import { openNotification } from '../../notification';
import { useTranslation } from 'react-i18next';
import useImageSave from '../../../hooks/useImageSave';
import { calculateImageWidthAndHeight } from '../GridDndEditor/gridHelper';
import { AddBlock } from '../GridDndEditor/models/addBlock.model';
import { StatusCodes } from '../helpers/StatusCodes';

interface ImageDropProps {
  documentId: string;
  setLoadingComponents?: any;
}

interface DroppedPosition {
  offset: number;
  top: number;
}

interface UploadErrorResponse {
  title: string;
  desc: string;
}

const IMAGE_DROP_BACKGROUND_CLASS = 'file-drop-background';

export const ImageDrop: React.FC<ImageDropProps> = ({ documentId, setLoadingComponents }) => {
  const [isCursorInsideEditor, setIsCursorInsideEditor] = useState<boolean>(false);
  const { t } = useTranslation();
  const { uploadImage } = useImageUploadMutation();
  const { imageSave } = useImageSave();
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

  function handleFileRead(formData: FormData, event, readerProgressEvent: ProgressEvent<FileReader>) {
    setIsLoadingImage(true);
    const img = new Image();
    img.onload = function () {
      const actualWidth = img.width;
      const actualHeight = img.height;
      const position = getDroppedPosition(event);
      if (!position) return;

      const { offset, top } = position;
      const { calculatedWidth, calculatedHeight } = calculateImageWidthAndHeight(actualWidth, actualHeight);

      const skeletonComponent: AddBlock = {
        x: offset,
        y: top,
        width: calculatedWidth,
        height: calculatedHeight,
      };
      setLoadingComponents((prev: AddBlock[]) => [...prev, skeletonComponent]);
      uploadImage(formData, {
        onSuccess: (response) => {
          imageSave(documentId, response.link, { x: offset, y: top, width: calculatedWidth, height: calculatedHeight });
          setIsLoadingImage(false);
        },
        onError: (response: any) => {
          handleErrorPrompt(response);
          setIsLoadingImage(false);
        },
      });
    };

    if (readerProgressEvent.target && typeof readerProgressEvent.target.result === 'string') {
      img.src = readerProgressEvent.target.result;
    }
  }

  const getDroppedPosition = (event): DroppedPosition | undefined => {
    const dropTarget = document.querySelector(`.${IMAGE_DROP_BACKGROUND_CLASS}`);

    if (!dropTarget) {
      return;
    }

    const rect = dropTarget.getBoundingClientRect();

    const offset = event.clientX - rect.left;
    const top = event.clientY - rect.top;

    return { offset, top };
  };

  const handleDroppedUrl = (droppedUrl, event) => {
    const position = getDroppedPosition(event);

    if (!position) return;
    const { offset, top } = position;
    const img = new Image();
    img.onload = function () {
      const actualWidth = img.width;
      const actualHeight = img.height;
      const { calculatedWidth, calculatedHeight } = calculateImageWidthAndHeight(actualWidth, actualHeight);

      imageSave(documentId, droppedUrl, { x: offset, y: top, width: calculatedWidth, height: calculatedHeight });
    };
    img.src = droppedUrl;
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const droppedUrlKey = 'text/uri-list';
    const droppedUrl = event.dataTransfer.getData(droppedUrlKey);
    if (droppedUrl) {
      handleDroppedUrl(droppedUrl, event);
    } else {
      const droppedFile = event.dataTransfer.files[0];
      if (droppedFile) {
        const reader = new FileReader();
        const formData = new FormData();
        formData.append('file', droppedFile);

        reader.onload = (e) => {
          handleFileRead(formData, event, e);
        };

        reader.readAsDataURL(droppedFile);
      }
    }
  };

  useEffect(() => {
    const images = document.querySelectorAll('img');
    images.forEach((image) => {
      image.addEventListener('dragover', (event) => event.preventDefault());
      image.addEventListener('drop', handleDrop);
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('dragover', (event) => event.preventDefault());
        image.removeEventListener('drop', handleDrop);
      });
    };
  }, [documentId, handleDrop]);

  useEffect(() => {
    if (!isLoadingImage) {
      setLoadingComponents([]);
    }
  }, [isLoadingImage]);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsCursorInsideEditor(event.target.classList.contains('froala-editor-wrapper'));
  };

  function getStatusError(code: number): UploadErrorResponse {
    switch (code) {
      case StatusCodes.UnsupportedType:
        return { title: t('document.image.errors.not_supported_types_title'), desc: t('document.image.errors.not_supported_types_desc') };
      case StatusCodes.SizeTooLarge:
        return { title: t('document.image.errors.size_restriction_title'), desc: t('document.image.errors.size_restriction_desc') };
      default:
        return {
          title: t('document.image.errors.generic_error_title'),
          desc: t('document.image.errors.generic_error_desc'),
        };
    }
  }

  const handleErrorPrompt = (response: any) => {
    const { title, desc } = getStatusError(response?.status_code);
    openNotification({
      title: title,
      description: desc,
      type: 'error',
    });
  };

  return (
    <div
      onDrop={isCursorInsideEditor ? undefined : handleDrop}
      onDragOver={handleDragOver}
      className={IMAGE_DROP_BACKGROUND_CLASS}
      style={{ visibility: isCursorInsideEditor ? 'hidden' : 'visible' }}
    ></div>
  );
};
