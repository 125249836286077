import { apiClient } from 'services/api';
import { Contact, User } from 'services/entities/PersonEntity';
import { ContactFactory, UserFactory } from 'services/repositories/factories/PersonFactory';
import { Endpoints } from 'utils/endpoints';
import { Proposal, Company, ShareTemplate } from '../../entities/Proposal';
import { ProposalStatus } from '../../entities/ProposalStatus';
import { ProposalRepository } from '../ProposalRepository';

export type GetProposalByIdApiResponse = {
  id: number;
  name: string;
  client_name: string;
  status_enum: string;
  person_id: number;
  document_lead: {
    name: string;
    user_id: string;
  };
  language_code: string;
};

export type GetAccountUsersApiResponse = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_primary?: number;
};

export type GetPersonApiResponse = {
  company_id?: number;
  id: number;
  email: string;
  first_name: string;
  last_name: string;
};

export type GetCompanyApiResponse = {
  people: GetAccountUsersApiResponse[];
};

export type GetShareTemplateApiResponse = {
  final_message: string;
  final_subject: string;
};

class ApiProposalRepositoryClass implements ProposalRepository {
  public async getProposalById(proposalId: number): Promise<Proposal> {
    const { id, name, client_name, status_enum, person_id, document_lead, language_code } = await apiClient.get<GetProposalByIdApiResponse>(
      Endpoints.getProposalById(proposalId)
    );

    const isSent = status_enum !== ProposalStatus.Unsent && status_enum !== ProposalStatus.Awaiting_Approval;

    return {
      id,
      name,
      clientName: client_name,
      isSent,
      personId: person_id,
      documentLead: { name: document_lead.name, userId: document_lead.user_id },
      languageCode: language_code,
    };
  }

  public async getAccountUsers(): Promise<User[]> {
    const data = await apiClient.get<GetAccountUsersApiResponse[]>(Endpoints.getUser);

    return data.map((resp) => UserFactory(resp));
  }

  public async getPersonById(personId: number): Promise<Contact> {
    const resp = await apiClient.get<GetPersonApiResponse>(Endpoints.getPersonById(personId));

    return ContactFactory(resp);
  }

  public async getCompanyById(companyId: number): Promise<Company> {
    const { people } = await apiClient.get<GetCompanyApiResponse>(Endpoints.getCompanyById(companyId));

    return {
      people: people.map((contact) => ContactFactory(contact)),
    };
  }

  public async getDefaultShareTemplate(proposalId: number): Promise<ShareTemplate> {
    const { final_message, final_subject } = await apiClient.get<GetShareTemplateApiResponse>(
      Endpoints.getShareProposalDefaultTemplate(proposalId)
    );

    return {
      finalMessage: final_message,
      finalSubject: final_subject,
    };
  }

  public async getProposalShareTemplate(payload: { proposalId: number; templateId: string }): Promise<ShareTemplate> {
    const { final_message, final_subject } = await apiClient.get<GetShareTemplateApiResponse>(Endpoints.getShareProposalTemplate(payload));

    return {
      finalMessage: final_message,
      finalSubject: final_subject,
    };
  }
}

export async function moveDocumentToTrash(documentId: string): Promise<void> {
  await apiClient.post(Endpoints.documentsTrash(documentId), null);
}

export async function restoreDocumentFromTrash(documentId: string): Promise<void> {
  await apiClient.delete(Endpoints.documentsTrash(documentId));
}

export async function deleteAllDocuments(): Promise<void> {
  await apiClient.delete(Endpoints.documents);
}

export async function duplicateDocument(documentId: string): Promise<string> {
  type Return = {
    document: {
      id: string;
    };
  };
  const data = await apiClient.post<Return>(Endpoints.duplicateDocument(documentId), {});
  return data.document.id;
}

export async function deleteDocument(documentId: string): Promise<void> {
  await apiClient.delete(Endpoints.deleteDocument(documentId));
}

export const ApiProposalRepository = new ApiProposalRepositoryClass();
