import { Icon } from 'components';
import './styles.less';

type Props = {
  value: string;
  handleSearchInput: (event) => void;
};

export const SearchWorkspaces = ({ value, handleSearchInput }: Props): JSX.Element => {
  return (
    <div className="search-workspaces__container" data-testId="search-accounts-input">
      <Icon name={'IcoSearch'} className="search-workspaces__icon" />
      <input
        className="search-workspaces__input"
        value={value}
        aria-label="Search Workspaces"
        name="searchWorkspaces"
        placeholder="Search"
        onChange={handleSearchInput}
      />
    </div>
  );
};
