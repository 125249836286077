import React, { useState, useRef, KeyboardEvent } from 'react';
import { Col, Layout, Row } from 'antd';
import DOMPurify from 'dompurify';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { AvatarButton, HelpButton, Paragraph, Button, Icon, Tooltip, openNotification } from 'components';
import PriceInput from 'components/priceInput';
import { Endpoints } from 'utils/endpoints';
import useUpdateDocumentMutation from 'hooks/useUpdateDocumentMutation';
import { DocumentShareModal } from 'components/editor/document-share-modal';
import { Header } from 'components/editor/document-share-modal/header';
import { DocumentShare } from 'components/editor/document-share';
import { SaveStatus } from 'providers/SignaturesProvider';
import { DocumentSaveStatus } from '../helpers/DocumentSaveStatus';
import { ErrorCode } from 'services/socket/SocketEvents';
import { UpdateDocumentPayload } from 'services/repositories/interfaces/DocumentRepository';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';

import './styles.less';
import { FeatureFlags } from '../../../utils/featureFlags';
import { EvaluateFeatureFlags } from '../../../routing/EvaluateFeatureFlags';

interface EditorMenuProps {
  documentId: string;
  documentTitle: string;
  status: string;
  price: number | null;
  documentStatus: DocumentSaveStatus;
  handleDocumentContentStatus: React.Dispatch<React.SetStateAction<SaveStatus>>;
}

const sanitizeTitle = (value: string) => {
  return DOMPurify.sanitize(value).substring(0, 99);
};

const blurOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    event.currentTarget.blur();
  }
};

const EditorMenu: React.FC<EditorMenuProps> = ({
  documentId,
  documentTitle,
  status,
  price,
  documentStatus,
  handleDocumentContentStatus,
}) => {
  const [title, setTitle] = useState<string>(documentTitle);
  const [documentPrice, setDocumentPrice] = useState<number | null>(price);
  const previewURL = Endpoints.getDocumentPreviewPage(documentId);
  const previewGridURL = Endpoints.getDocumentPreviewGridPage(documentId);
  const handlePreviewRedirect = () => window.open(previewURL, '_blank');
  const handlePreviewGridRedirect = () => window.open(previewGridURL, '_blank');
  const documentTitleRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
  const { mutate } = useUpdateDocumentMutation();
  const { t } = useTranslation();
  const { data } = useGetDocumentByIdQuery(documentId);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const sanitizedValue = sanitizeTitle(event.target.value);
    setTitle(sanitizedValue);
  }

  const updateDocumentProperties = (payload: Omit<UpdateDocumentPayload, 'documentId'>) => {
    mutate(
      { documentId, ...payload },
      {
        onSuccess: () => {
          handleDocumentContentStatus({ status: DocumentSaveStatus.SAVED, errCode: '' });
        },
        onError: (error: unknown) => {
          if (error instanceof AxiosError && error.response?.status === 423) {
            handleDocumentContentStatus({ status: DocumentSaveStatus.NOT_SAVED, errCode: ErrorCode.LOCKED });
          } else {
            openNotification({
              title: t('error'),
              description: t('document.menu.update_error'),
              type: 'error',
            });
          }
        },
      }
    );
  };

  const updateTitle = () => {
    const sanitizedTitle = sanitizeTitle(title);
    if (!sanitizedTitle.trim()) {
      const defaultTitle = t('editor.default_document_title');
      setTitle(defaultTitle);
      persistTitle({ title: defaultTitle });
    } else {
      persistTitle({ title: sanitizedTitle });
    }
  };

  const persistTitle = (titlePayload: object) => {
    updateDocumentProperties(titlePayload);
  };

  const updatePrice = () => {
    updateDocumentProperties({ price: documentPrice });
  };

  const [isVisible, setIsVisible] = useState(false);

  const closeShareModal = () => setIsVisible(false);
  const openShareModal = () => setIsVisible(true);

  const documentTitleWithCompanyName = [title, data?.company?.name].filter(Boolean).join(' - ');

  return (
    <Layout.Header className="editor-header">
      <Row justify="space-between" align="top" className="editor-menu-container">
        <Col className="editor-menu-container__column--left document-details">
          <Tooltip title={title} placement="bottom">
            <input
              ref={documentTitleRef}
              onKeyDown={blurOnEnter}
              className="document-title"
              maxLength={100}
              placeholder={t('editor.default_document_title')}
              onChange={onChange}
              onBlur={updateTitle}
              value={title}
            />
          </Tooltip>
          <div className="document-status">
            <Paragraph size="sm">{status}</Paragraph>
            $<PriceInput value={documentPrice} onChange={setDocumentPrice} onBlur={updatePrice} />
            <Paragraph size="sm" className="timestamp-ml-xs">
              {documentStatus == DocumentSaveStatus.NOT_SAVED && t('document.save_statuses.not_saved')}
              {documentStatus == DocumentSaveStatus.SAVING && t('document.save_statuses.saving')}
              {documentStatus == DocumentSaveStatus.SAVED && t('document.save_statuses.saved')}
            </Paragraph>
          </div>
        </Col>
        <Col className="editor-menu-container__column--right">
          <div className="buttons-container">
            <EvaluateFeatureFlags
              flags={[FeatureFlags.snapToGrid]}
              onConditionFailed={() => (
                <Button
                  icon={<Icon name="IcoPreview" title={t('preview')} />}
                  variant="neutral"
                  type="default"
                  className="preview-button"
                  onClick={handlePreviewRedirect}
                >
                  {t('preview')}
                </Button>
              )}
            >
              <Button
                icon={<Icon name="IcoPreview" title={t('preview')} />}
                variant="neutral"
                type="default"
                className="preview-button"
                onClick={handlePreviewGridRedirect}
              >
                {t('preview')}
              </Button>
            </EvaluateFeatureFlags>
            <Button
              icon={<Icon name="IcoSend" title={t('share_document')} />}
              variant="positive"
              type="primary"
              className="send-button"
              onClick={openShareModal}
            >
              {t('share_document')}
            </Button>
            <DocumentShareModal
              open={isVisible}
              onCancel={closeShareModal}
              title={<Header title={t('share.title')} description={documentTitleWithCompanyName} />}
              destroyOnClose
            >
              <DocumentShare documentId={documentId} onSuccess={closeShareModal} />
            </DocumentShareModal>
          </div>
          <HelpButton />
          <AvatarButton />
        </Col>
      </Row>
      <div id="editor-toolbar-container"></div>
    </Layout.Header>
  );
};

export default EditorMenu;
