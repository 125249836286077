import { Outlet } from 'react-router-dom';
import { ApiUserRepository } from 'services/repositories/implementations/ApiUserRepository';
import { ApiUserPermissionsRepository } from 'services/permissions/implementations/ApiUserPermissionsRepository';
import { RefreshAuthTokenInBackground } from 'services/backgroundrefreshtoken/refresh-auth-token-in-background';
import UserProvider from 'providers/UserProvider';
import PermissionsProvider from 'providers/PermissionsProvider';

const userRepository = new ApiUserRepository();
const userPermissionsRepository = new ApiUserPermissionsRepository();
export function AuthenticatedLayoutRoute() {
  return (
    <UserProvider userRepository={userRepository}>
      <PermissionsProvider repository={userPermissionsRepository}>
        <RefreshAuthTokenInBackground />
        <Outlet />
      </PermissionsProvider>
    </UserProvider>
  );
}
