import useFeatureFlag from 'hooks/useFeatureFlag';
import { ReactNode } from 'react';

type FeatureFlagsResult = Record<string, boolean>;

interface Props {
  flags: string[];
  children: ReactNode;
  onConditionFailed: (result: FeatureFlagsResult) => void;
  loadingIndicator?: React.ReactNode;
}

export function EvaluateFeatureFlags({ flags, children, onConditionFailed, loadingIndicator }: Props) {
  const featureFlags = useFeatureFlag(flags);
  const isLoading = flags.length > 0 && Object.keys(featureFlags).length === 0;

  if (isLoading) {
    return loadingIndicator ? <>{loadingIndicator}</> : null;
  }

  const isEvaluationPassed = flags.every((flag) => {
    return featureFlags[flag];
  });

  if (!isEvaluationPassed) {
    return <>{onConditionFailed(featureFlags)}</>;
  }

  return <>{children}</>;
}
