import { useDispatch } from 'react-redux';
import { useSocketClient } from '../providers/SocketContext';
import { useCurrentUser } from '../providers/UserProvider';
import { addGridBlockWithSocket } from '../components/editor/grid/reduxStore/saveReducers';
import { addGridBlockState } from '../components/editor/grid/reduxStore/editorSlice';
import { AnyAction } from '@reduxjs/toolkit';
import { AddBlock } from 'components/editor/GridDndEditor/models/addBlock.model';
import { GridBlockType } from 'components/editor/shared/gridBlockType';

export default function useImageSave() {
  const dispatch = useDispatch();
  const socketClient = useSocketClient();
  const { data } = useCurrentUser();
  const userId = data.id;

  const imageSave = (documentId: string, imageContent: string, blockConfig: AddBlock) => {
    dispatch(
      addGridBlockWithSocket({
        documentId,
        userId,
        content: imageContent,
        blockConfig,
        socketClient,
        type: GridBlockType.IMAGE,
        callback: (response) => {
          const gridBlockId = JSON.parse(response).content.gridId;
          const updatedConfig = {
            ...blockConfig,
            id: gridBlockId,
          };
          const payload = {
            documentId: documentId,
            content: imageContent,
            blockConfig: updatedConfig,
            gridBlockId: gridBlockId,
            blockType: GridBlockType.IMAGE,
          };
          dispatch(addGridBlockState(payload));
        },
      }) as unknown as AnyAction
    );
  };

  return { imageSave };
}
