import React, { useCallback, useMemo, useState } from 'react';
import { Layouts } from 'react-grid-layout';
import { type Layout } from 'react-grid-layout';

type GridBlock = Layout;

interface GridBlocksContextType {
  gridblocks: { lg: GridBlock[] };
  addGridBlock: (gridblock: GridBlock) => void;
  selectedGridBlock: string | null;
  setSelectedGridBlock: (selectedGridBlock: string | null) => void;
  updateGridBlocks: (currentLayout: Layout[], allLayouts: Layouts) => void;
  triggerReRender: () => void;
}

export const GridBlocksContext = React.createContext<GridBlocksContextType>({} as GridBlocksContextType);

interface GridBlocksProviderProps {
  children: React.ReactNode;
}

const availableHandles: Layout['resizeHandles'] = ['se'];
function excludePlaceholderLayout(layout: Layout) {
  return !layout.i.startsWith('__');
}
export const GridBlocksProvider = ({ children }: GridBlocksProviderProps) => {
  const [gridblocks, setGridBlocks] = useState<{ lg: GridBlock[] }>({ lg: [] });
  const [selectedGridBlock, setSelectedGridBlock] = useState<string | null>(null);

  const addGridBlock = useCallback((gridblock: GridBlock) => {
    setGridBlocks((prevGridBlocks) => {
      const result = {
        lg: [...prevGridBlocks.lg.filter(excludePlaceholderLayout), { ...gridblock, resizeHandles: availableHandles }],
      };
      return result;
    });
  }, []);

  const updateGridBlocks = useCallback((_: Layout[], allLayouts: Layouts) => {
    setGridBlocks({ lg: allLayouts.lg });
  }, []);

  const triggerReRender = useCallback(() => {
    setGridBlocks((prev) => {
      return { lg: prev.lg.filter(excludePlaceholderLayout) };
    });
  }, []);

  const value = useMemo(() => {
    return {
      gridblocks,
      addGridBlock,
      selectedGridBlock,
      setSelectedGridBlock,
      updateGridBlocks,
      triggerReRender,
    };
  }, [gridblocks, setSelectedGridBlock, selectedGridBlock, updateGridBlocks, addGridBlock]);

  return <GridBlocksContext.Provider value={value}>{children}</GridBlocksContext.Provider>;
};
