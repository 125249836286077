import { FunctionComponent } from 'react';
import { Loading } from '..';
import './styles.less';

interface Props {
  isLoading: boolean;
  isLoaded: boolean;
}

const PageLoader: FunctionComponent<Props> = ({ isLoaded, isLoading, children }) => {
  return !isLoaded ? (
    <div className="page-loader">
      <Loading isLoading={isLoading} />
    </div>
  ) : (
    <>{children}</>
  );
};

export default PageLoader;
