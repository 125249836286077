import { notification } from 'antd';
import './index.less';
import Icon from '../icon';
import { Props } from './interface';
import { Heading, Paragraph } from '../typography';

const getIcon = (type: Props['type']) => {
  switch (type) {
    case 'error':
      return <Icon name="IcoError" className="lighthouse" />;
    case 'info':
      return <Icon name="IcoInfo" className="lake" />;
    case 'warning':
      return <Icon name="IcoWarning" className="sunrise" />;
    case 'success':
      return <Icon name="IcoSuccess" className="seaglass" />;
  }
};

notification.config({
  maxCount: 3,
  placement: 'bottomRight',
  duration: 4.5,
});

export const openNotification = ({ type, title, description, duration = 4.5, placement = 'bottomRight' }: Props): void => {
  notification[type]({
    message: <Heading level={5}>{title}</Heading>,
    description: <Paragraph size="sm">{description}</Paragraph>,
    placement: placement,
    duration: duration,
    closeIcon: <Icon name="IcoCloseX" className="fog" title="Close" />,
    icon: getIcon(type),
  });
};
