/* eslint-disable react/no-unescaped-entities */
// TODO: remove the eslint disable and fix the issue
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader, ButtonPageAction, ContentCard, Heading } from '../../../components';
import './styles.less';
import { Row, Col } from 'antd';
import { useIsAccountPausable } from 'hooks/useIsAccountPausable';
import { useCurrentUser } from 'providers/UserProvider';

const FAQ: React.FC = () => {
  const { t } = useTranslation();
  const {
    data: { accountId },
  } = useCurrentUser();
  const isPausable = useIsAccountPausable({ accountId, enabled: !!accountId });

  return (
    <>
      <PageHeader
        actions={[
          <ButtonPageAction key="help" type="primary">
            <a href="https://support.proposify.com/" target="_blank" rel="noopener">
              {t('settings.plan.faq.open_help_centre')}
            </a>
          </ButtonPageAction>,
        ]}
        paragraph={t('settings.plan.faq.subtitle')}
        title={t('settings.plan.faqs')}
      />
      <Row gutter={[24, 20]}>
        <Col md={12} sm={12} xs={24}>
          <ContentCard
            content={
              <>
                <Heading level={5}>{t('settings.plan.faq.item_1.question')}</Heading>
                <div>
                  <p>{t('settings.plan.faq.item_1.answer.p_1')}</p>
                  <p>{t('settings.plan.faq.item_1.answer.p_2')}</p>
                </div>
              </>
            }
          />
        </Col>
        <Col md={12} sm={12} xs={24}>
          <ContentCard
            content={
              <>
                <Heading level={5}>{t('settings.plan.faq.item_2.question')}</Heading>
                <div>
                  <p>{t('settings.plan.faq.item_2.answer.p_1')}</p>
                  <p>{t('settings.plan.faq.item_2.answer.p_2')}</p>
                  <p>
                    Check out our{' '}
                    <a href="https://www.proposify.com/professional-services" target="_blank" rel="noopener">
                      {' '}
                      Professional Services
                    </a>{' '}
                    page for more information.
                  </p>
                </div>
              </>
            }
          />
        </Col>
        <Col md={12} sm={12} xs={24}>
          <ContentCard
            content={
              <>
                <Heading level={5}>{t('settings.plan.faq.item_3.question')}</Heading>
                <div>
                  <p>{t('settings.plan.faq.item_3.answer.p_1')}</p>
                  <p>{t('settings.plan.faq.item_3.answer.p_2')}</p>
                  <p>
                    Check out our{' '}
                    <a href="https://www.proposify.com/professional-services" target="_blank" rel="noopener">
                      {' '}
                      Professional Services
                    </a>{' '}
                    page for more information and let us transform your proposals so you can be ready to impress.
                  </p>
                </div>
              </>
            }
          />
        </Col>
        <Col md={12} sm={12} xs={24}>
          <ContentCard
            content={
              <>
                <Heading level={5}>{t('settings.plan.faq.item_4.question')}</Heading>
                <div>
                  <p>{t('settings.plan.faq.item_4.answer.p_1')}</p>
                  <p>{t('settings.plan.faq.item_4.answer.p_2')}</p>
                  <p>
                    Check out our{' '}
                    <a href="https://www.proposify.com/professional-services" target="_blank" rel="noopener">
                      Professional Services
                    </a>{' '}
                    page for more information.
                  </p>
                </div>
              </>
            }
          />
        </Col>
        <Col md={12} sm={12} xs={24}>
          <ContentCard
            content={
              <>
                <Heading level={5}>{t('settings.plan.faq.item_5.question')}</Heading>
                <div>
                  <p>{t('settings.plan.faq.item_5.answer.p_1')}</p>
                  <p>
                    Kindly note that support is not available on our Free Plan. We encourage you to explore our extensive{' '}
                    <a href="https://links.proposify.com/support/knowledge-base" target="_blank" rel="noopener">
                      Knowledge Base
                    </a>{' '}
                    to troubleshoot and learn best practices on using Proposify and your available features. If you wish to receive email or
                    chat support from our team, you will need to upgrade to one of our
                    <a href="https://www.proposify.com/pricing" target="_blank" rel="noopener">
                      {' '}
                      paid plans
                    </a>
                    .
                  </p>
                </div>
              </>
            }
          />
        </Col>
        <Col md={12} sm={12} xs={24}>
          <ContentCard
            content={
              <>
                <Heading level={5}>{t('settings.plan.faq.item_6.question')}</Heading>
                <div>
                  <p>
                    Keeping your data safe is a priority. We are dedicated to doing this through continued compliance efforts, ongoing
                    audits, and 24/7 system monitoring to ensure we meet and exceed industry standards. Proposals are sent using secure
                    encrypted links and we never disclose your information to third parties. We partner with Amazon for cloud hosting and
                    your data is protected by 256-bit SSL security. We are also GDPR and SOC2 compliant. Learn more on our{' '}
                    <a href="https://www.proposify.com/security" target="_blank" rel="noopener">
                      Security
                    </a>{' '}
                    page.
                  </p>
                </div>
              </>
            }
          />
        </Col>
        <Col md={12} sm={12} xs={24}>
          <ContentCard
            content={
              <>
                <Heading level={5}>{t('settings.plan.faq.item_7.question')}</Heading>
                <div>
                  <p>{t('settings.plan.faq.item_7.answer.p_1')}</p>
                </div>
              </>
            }
          />
        </Col>
        <Col md={12} sm={12} xs={24}>
          <ContentCard
            content={
              <>
                <Heading level={5}>{t('settings.plan.faq.item_8.question')}</Heading>
                <div>
                  <p>
                    Our free trial gives you access to all of the features on our{' '}
                    <a href="https://www.proposify.com/pricing" target="_blank" rel="noopener">
                      Team plan
                    </a>{' '}
                    for 14 days. We recommend taking things for a spin to see which ones work best for your team and process. You can create
                    and send real proposals to real clients during your trial. Once your trial is over, your clients can still view and
                    accept any proposals you sent to them during the trial period.
                  </p>
                </div>
              </>
            }
          />
        </Col>
        <Col md={12} sm={12} xs={24}>
          <ContentCard
            content={
              <>
                <Heading level={5}>{t('settings.plan.faq.item_9.question')}</Heading>
                <div>
                  <p>{t('settings.plan.faq.item_9.answer.p_1')}</p>
                </div>
              </>
            }
          />
        </Col>
        <Col md={12} sm={12} xs={24}>
          <ContentCard
            content={
              <>
                <Heading level={5}>{t('settings.plan.faq.item_10.question')}</Heading>
                <div>
                  <p>{t('settings.plan.faq.item_10.answer.p_1')}</p>
                </div>
              </>
            }
          />
        </Col>
        <Col md={12} sm={12} xs={24}>
          <ContentCard
            content={
              <>
                <Heading level={5}>{t(!isPausable ? 'settings.plan.faq.item_11.question' : 'settings.plan.faq.item_12.question')}</Heading>
                <div>
                  {!isPausable ? (
                    <p>
                      No problem at all. You can upgrade, downgrade, or cancel your account any time (although we'd hate to see you go!).{' '}
                      <a href={`${process.env.REACT_APP_LEGACY_APP_URL}/settings/cancel`}>Click here if you need to cancel.</a> Just a
                      warning though: Once you cancel your account, all of your data is permanently deleted. Like, forever.
                    </p>
                  ) : (
                    <p>
                      If you don't have any proposals to send out, we'll let you pause our account so you won't be charged, and come back
                      when you're ready to close deals again.
                      <a href={`${process.env.REACT_APP_LEGACY_APP_URL}/settings/status`}>Click here to pause your billing.</a>
                      Want to cancel permanently?{' '}
                      <a href={`${process.env.REACT_APP_LEGACY_APP_URL}/settings/cancel`}>Click here if you need to delete.</a>
                    </p>
                  )}
                </div>
              </>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default FAQ;
