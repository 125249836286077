import * as React from 'react';
const SvgIcoTrash = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M4.697 5.863a.75.75 0 01.55-.24h13.506a.75.75 0 01.748.807l-.966 12.553a3.001 3.001 0 01-2.992 2.77H8.457a3 3 0 01-2.992-2.77L4.499 6.43a.75.75 0 01.198-.567zm10.846 14.39a1.5 1.5 0 001.496-1.385l.904-11.745H6.057l.904 11.745a1.5 1.5 0 001.496 1.386h7.086z" />
      <path d="M3.247 6.373a.75.75 0 01.75-.75h16.006a.75.75 0 010 1.5H3.997a.75.75 0 01-.75-.75z" />
      <path d="M9.186 3.746a.375.375 0 00-.375.376v1.5h6.378v-1.5a.375.375 0 00-.375-.376H9.186zm-1.875.376c0-1.036.84-1.876 1.875-1.876h5.628c1.035 0 1.875.84 1.875 1.876v2.25a.75.75 0 01-.75.75H8.061a.75.75 0 01-.75-.75v-2.25zM13.97 10.124a.75.75 0 01.75.75v5.628a.75.75 0 11-1.5 0v-5.627a.75.75 0 01.75-.75zM10.03 10.124a.75.75 0 01.75.75v5.628a.75.75 0 01-1.5 0v-5.627a.75.75 0 01.75-.75z" />
    </g>
  </svg>
);
export default SvgIcoTrash;
