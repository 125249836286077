import { useMutation } from 'react-query';
import { SignSignaturePayload, SignatureRepository, SignedSignatureBox } from '../services/repositories/interfaces/SignatureRepository';
import { Position } from '../components/editor/signatures/interfaces';

export interface SignedSignatureResponse {
  signee: string;
  signatureId: string;
  position: Position;
  signedDate: string;
}

export const useSignSignatureMutation = (signatureRepository: SignatureRepository) => {
  return useMutation<SignedSignatureBox, unknown, SignSignaturePayload>({
    mutationFn: async (payload) => {
      return signatureRepository.signSignature(payload);
    },
  });
};
