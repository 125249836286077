import React, { forwardRef, ReactNode, useCallback, useState } from 'react';
import {
  GridBlockAddHandler,
  GridBlockChangeHandler,
  GridBlockDimensionChangeHandler,
  GridBlockPositionChangeHandler,
} from './GridDndEditor';
import { Rnd } from 'react-rnd';
import Draggable from 'react-draggable';
import { VariablesGroup } from '../../../services/entities/VariablesEntity';
import { GridBlockContent } from '../grid/reduxStore/editorSlice';
import { default as DragIcon } from '../../icon/icons/IcoDrag';
import { default as ResizeIcon } from '../../icon/icons/IcoGridResize';
import { Block } from './models/block.model';

export interface DraggableGridBlockWrapperProps {
  configOptions?: object | undefined;
  variables?: VariablesGroup;
  gridBlock: GridBlockContent;
  children?: ReactNode;
  gridSystemInPx: number;
  gridRef?: React.MutableRefObject<HTMLDivElement | null>;
  gridBlockAddHandler?: GridBlockAddHandler;
  gridBlockChangeHandler?: GridBlockChangeHandler;
  gridBlockPositionChangeHandler?: GridBlockPositionChangeHandler;
  gridBlockDimensionChangeHandler?: GridBlockDimensionChangeHandler;
  onDragStop?: (e, data, item: Block) => void;
  handleResizeStop: (e, direction, ref, delta, item: Block) => void;
  handleStop: (e, data, item: Block) => void;
  handleDrag: (e, item: Block) => void;
  style?: React.CSSProperties | undefined;
}

export const DraggableGridBlockWrapper = forwardRef<HTMLDivElement, DraggableGridBlockWrapperProps>(
  ({ gridBlock, children, gridSystemInPx, handleResizeStop, handleDrag, handleStop, style }) => {
    const [hoveredBlocksIds, setHoveredBlocksIds] = useState<string[]>([]);

    const handleMouseEnter = useCallback((blockId: string) => {
      setHoveredBlocksIds((prev) => [...prev, blockId]);
      return true;
    }, []);

    const handleMouseLeave = (blockId: string) => {
      setHoveredBlocksIds((prev) => prev.filter((id) => id !== blockId));
    };

    const onResizeStop = (e, direction, ref, delta, item: Block) => {
      const { width, height } = ref.style;
      const updatedItem: Block = {
        ...item,
        width: parseInt(width, 10),
        height: parseInt(height, 10),
      };
      handleResizeStop(e, direction, ref, delta, updatedItem);
    };

    return (
      <div
        data-testid="grid_block_container_test"
        className={`grid_block_${gridBlock.id}`}
        onMouseEnter={() => handleMouseEnter(gridBlock.id)}
        onMouseLeave={() => handleMouseLeave(gridBlock.id)}
      >
        <Draggable
          defaultClassName="editor__page__draggable"
          handle=".editor__page__grid-drag-handle"
          position={{ x: gridBlock.blockConfig.x, y: gridBlock.blockConfig.y }}
          grid={[gridSystemInPx, gridSystemInPx]}
          scale={1}
          onStop={(e, data) => handleStop(e, data, gridBlock.blockConfig)}
          onDrag={(e, data) =>
            handleDrag(e, {
              ...gridBlock.blockConfig,
              x: data.x,
              y: data.y,
            })
          }
        >
          <Rnd
            size={{
              width: gridBlock.blockConfig.width || 0,
              height: gridBlock.blockConfig.height || gridSystemInPx,
            }}
            position={{
              x: gridBlock.blockConfig.x,
              y: gridBlock.blockConfig.y,
            }}
            minWidth={gridSystemInPx}
            style={style}
            minHeight={gridSystemInPx}
            dragGrid={[gridSystemInPx, gridSystemInPx]}
            resizeGrid={[gridSystemInPx, gridSystemInPx]}
            dragHandleClassName="editor__page__grid-drag-handle"
            resizeHandleClasses={{ bottomRight: 'editor__page__grid-resize-handle' }}
            enableResizing={{ bottomRight: true }}
            onResize={(e, direction, ref, delta) => onResizeStop(e, direction, ref, delta, gridBlock.blockConfig)}
            onResizeStop={(e, direction, ref, delta) => onResizeStop(e, direction, ref, delta, gridBlock.blockConfig)}
          >
            <div
              data-testid="grid_block_hovered_test"
              className={`grid_block_${gridBlock.id} ${hoveredBlocksIds.includes(gridBlock.id) ? 'grid_block_hovered' : ''}`}
            >
              <>
                {hoveredBlocksIds.includes(gridBlock.id) && (
                  <>
                    <div data-testid="grid_block_handle_test" className="editor__page__grid-drag-handle">
                      <DragIcon />
                    </div>
                    <div className="editor__page__grid-resize-handle">
                      <ResizeIcon />
                    </div>
                  </>
                )}
              </>
              {children}
            </div>
          </Rnd>
        </Draggable>
      </div>
    );
  }
);

DraggableGridBlockWrapper.displayName = 'DraggableGridBlockWrapper';
