import { PlanEntity } from '../../../services/entities/PlanEntity';
import { PlanApiResponse } from '../../../services/objects/http/PlanApiResponse';
import { PlanRepository } from '../interfaces/PlanRepository';
import { Endpoints } from '../../../utils/endpoints';
import { apiClient } from 'services/api';

export class ApiPlanRepository implements PlanRepository {
  public async getAccountPlan(accountId: number): Promise<PlanEntity> {
    const { is_trial, pausable, id, custom, type, name, can_self_downgrade, isSwitchingToTeamPlanADowngrade } =
      await apiClient.get<PlanApiResponse>(Endpoints.getAccountPlan(accountId));

    return {
      pausable,
      isTrial: is_trial,
      custom,
      name,
      id,
      type,
      canSelfDowngrade: can_self_downgrade,
      isSwitchingToTeamPlanADowngrade,
    };
  }
}
