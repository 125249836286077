import HelpButton from '../helpbutton/helpbutton';
import Menu from 'components/menu';
import Logo from 'components/logo';
import AvatarButton from 'components/avatar';
import { useMediaQuery } from 'hooks/useMediaQuery';
import './styles.less';

const MobileMenu = (): JSX.Element => {
  const { isTablet } = useMediaQuery();

  return (
    <div className="app-sidebar-mobile">
      <div className="sidebar-mobile-left">
        <Menu />
        <Logo title="proposify-logo" className="light-bg" href="/" />
      </div>
      <div className="sidebar-mobile-right">
        {isTablet && <HelpButton />}
        <AvatarButton />
      </div>
    </div>
  );
};

export default MobileMenu;
