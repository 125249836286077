import { useSelector } from 'react-redux';
import { RootState } from '../grid/reduxStore/store';
import { GridBlockType } from '../shared/gridBlockType';
import './style.less';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, ImageList, ImageListItem } from '@mui/material';
import { useDocumentImageLibrary } from '../../../hooks/useDocumentImageLibrary';
import { DocumentImageLibraryApiResponse } from '../../../services/repositories/implementations/ApiDocumentRepository';
import { openNotification } from '../../notification';
import { useTranslation } from 'react-i18next';
import ImageUploadButton from './ImageUploadButton/ImageUploadButton';

export default function ImageLibrary() {
  const IMAGE_LIBRARY_COLUMNS = 2,
    IMAGE_LIBRARY_GAP = 8;
  const { documentId } = useParams<{ documentId: string }>();
  const gridBlocks = useSelector((state: RootState) => {
    const document = state.gridBlocks.documents.find((doc) => doc.id === documentId);
    return document?.gridBlocks || [];
  });
  const imageQueryString = '?w=248&fit=crop&auto=format';
  const imageQueryStringHighRes = '?w=248&fit=crop&auto=format&dpr=2 2x';
  const { result, isError, isFetched, refetch } = useDocumentImageLibrary(documentId || '');
  const { t } = useTranslation();
  let libraryImages = result;

  if (isError) {
    openNotification({
      type: 'error',
      title: t('document.image.errors.library_image_load_fail'),
      description: '',
      placement: 'topRight',
    });
  }

  // Filter out unique image grid blocks
  const imageList = useMemo(() => {
    const uniqueUrls = new Set<string>();

    gridBlocks.forEach((gridBlock) => {
      if (gridBlock.type === GridBlockType.IMAGE) {
        uniqueUrls.add(gridBlock.content);
      }
    });

    if (isFetched) {
      (libraryImages as DocumentImageLibraryApiResponse).forEach((image) => {
        uniqueUrls.add(image.link);
      });
    }

    return uniqueUrls;
  }, [gridBlocks, isFetched, libraryImages]);

  const refreshImages = async () => {
    const response = await refetch();
    if (response?.data) {
      libraryImages = response.data;
    }
  };

  return (
    <div className="image__library__container">
      <div className="image__container_title">IMAGES</div>
      <div className="image__heading__section">LIBRARY</div>
      <div className="image__content__section">
        <Box className="image__library__box">
          <ImageList variant="masonry" cols={IMAGE_LIBRARY_COLUMNS} gap={IMAGE_LIBRARY_GAP} draggable={true}>
            <ImageListItem key={0}>
              <ImageUploadButton refreshImages={refreshImages} />
            </ImageListItem>
            {Array.from(imageList).map((item, index) => (
              <ImageListItem key={index}>
                <img
                  srcSet={`${item}${imageQueryStringHighRes}`}
                  src={`${item}${imageQueryString}`}
                  alt={`Image ${index}`}
                  loading="lazy"
                  draggable={true}
                  onDragStart={(event) => {
                    event.dataTransfer.setData('text/uri-list', item);
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </div>
    </div>
  );
}

ImageLibrary.displayName = 'ImageLibrary';
