export enum Events {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  CONNECT_ERROR = 'connect_error',
  CONTENT_GET = 'content_get',
  CONTENT_CHANGED = 'content_changed',
  SIGNATURE_GET_ALL = 'get_all_signatures',
  SIGNATURE_UPDATE = 'update_signature',
  SIGNATURE_ADD = 'add_signature',
  SIGNATURE_DELETE = 'delete_signature',
  GRID_ADD = 'grid:added',
  GRID_CONTENT_CHANGED = 'grid:content_changed',
  GRID_POSITION_CHANGED = 'grid:position_changed',
  GRID_DIMENSION_CHANGED = 'grid:dimension_changed',
  GRID_INITIAL_LOAD = 'grid:load_content',
}

export enum AcknowledgmentResponseStatus {
  OK = 'Ok',
  FAILURE = 'Failure',
}

export enum ErrorCode {
  LOCKED = 'Locked',
}
