import AsyncCreatableSelect, { AsyncCreatableProps } from 'react-select/async-creatable';
import { AsyncAdditionalProps } from 'react-select/dist/declarations/src/useAsync';
import { components, GroupBase, OptionProps, PlaceholderProps } from 'react-select';
import { Avatar, List } from 'antd';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';
import './styles.less';
import { getSignatureColor } from 'components/editor/sidepanel/signatures/signature-base-button';
import Icon from 'components/icon';
import { AssignedSigneeType } from 'services/repositories/interfaces/SignatureRepository';

export interface SignatureSelectOption {
  label: string;
  value: string;
  id: number;
  email: string;
  initials: string;
  firstName: string;
  lastName: string;
  type: AssignedSigneeType;
}

type LoadOptionsFunc = (
  accountId: number
) => AsyncAdditionalProps<SignatureSelectOption, { options: SignatureSelectOption[] }>['loadOptions'];

const Option = (props: OptionProps<SignatureSelectOption, false>) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <List.Item.Meta
        className="signature_dropdown__option__meta"
        avatar={
          <Avatar className={`user_color ${getSignatureColor(data.email)}`} alt={data.label}>
            {data.initials}
          </Avatar>
        }
        title={data.label}
        description={data.email}
      />
    </components.Option>
  );
};

const Placeholder = (props: PlaceholderProps<SignatureSelectOption, false>) => {
  return (
    <components.Placeholder {...props}>
      <Icon name="IcoSearch" /> {props.children}
    </components.Placeholder>
  );
};

const customComponents = {
  Option,
  Placeholder,
};

function getInitials(...args: string[]): string {
  return args
    .filter(Boolean)
    .map((str) => str[0])
    .reduce((value, char) => value.toUpperCase() + char.toUpperCase(), '')
    .substring(0, 2);
}

export const loadSignatureOptions: LoadOptionsFunc = (accountId) => (inputValue: string, callback) => {
  if (inputValue.length < 3) {
    return callback([]);
  }
  apiDocumentRepository
    .getSignees(accountId, { searchText: inputValue || undefined, offset: 0, limit: 10 })
    .then((response) => {
      callback(
        response.signees.map((signee) => ({
          ...signee,
          label: signee.fullName,
          value: signee.uid,
          id: signee.id,
          email: signee.email,
          initials: getInitials(signee.firstName, signee.lastName),
          type: signee.signeeType === 'USER' ? AssignedSigneeType.User : AssignedSigneeType.Person,
        }))
      );
    })
    .catch(() => {
      callback([]);
    });
};

type Props = AsyncCreatableProps<SignatureSelectOption, false, GroupBase<SignatureSelectOption>>;

export function SignatureSelect(props: Props) {
  return (
    <AsyncCreatableSelect
      isClearable
      cacheOptions
      defaultOptions
      isMulti={false}
      components={customComponents}
      isValidNewOption={() => false}
      formatCreateLabel={() => 'Add signee'}
      {...props}
    />
  );
}
