import { CONFIG } from '../../../config';
import { Endpoints } from '../../../utils/endpoints';
import { AuthCookieService } from '../../../services/cookie';

export const editorConfig = {
  height: '100%',
  pasteDeniedTags: ['img'],
  placeholderText: 'Start typing here...',
  key: CONFIG.FROALA_LICENSE_KEY,
  toolbarContainer: '#editor-toolbar-container',
  charCounterCount: false,
  quickInsertEnabled: false,
  imageUpload: true,
  imageMaxSize: 5242880, // 5MB
  acceptedImageTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
  requestHeaders: {
    Authorization: `Bearer ${AuthCookieService.getAccessToken()}`,
  },
  imageUploadURL: `${process.env.REACT_APP_API_ENDPOINT}${Endpoints.uploadImage}`,
  toolbarButtons: [
    'undo',
    'redo',
    '|',
    'paragraphFormat',
    'fontFamily',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    'fontSize',
    '|',
    'alignLeft',
    'alignCenter',
    'alignRight',
    'alignJustify',
    '|',
    'formatUL',
    'formatOL',
    'lineHeight',
    'insertLink',
    'insertImage',
    'clearFormatting',
  ],
  paragraphFormatSelection: true,
  fontFamilySelection: true,
  attribution: false,
};

export const defaultFont = [
  { value: 'Arial,Helvetica,sans-serif', name: 'Arial' },
  { value: 'Georgia,serif', name: 'Georgia' },
  { value: 'Impact,Charcoal,sans-serif', name: 'Impact' },
  { value: 'Tahoma,Geneva,sans-serif', name: 'Tahoma' },
  { value: 'Verdana,Geneva,sans-serif', name: 'Verdana' },
  { value: "'Times New Roman',Times,serif", name: 'Times New Roman' },
];
