import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { ApiEditorRepository } from '../services/repositories/implementations/ApiEditorRepository';

export function useProposalContent(proposalId: string, enabled = true) {
  const editorRepository = new ApiEditorRepository();
  const { data, isError } = useQuery(QueryKeys.getProposalContent(proposalId), () => editorRepository.getProposalContent(proposalId), {
    enabled,
  });

  // TODO: Remove this from here and handle it at route level component.
  // Reason: Randomly calling window.location inside hook is not a good way to test or maintain a reliable code.
  if (isError) {
    window.location.replace(`${process.env.REACT_APP_LEGACY_APP_URL}/error`);
  }

  return {
    data,
    isError,
  };
}
