interface Area {
  top: number;
  left: number;
  bottom: number;
  right: number;
}
interface Point {
  x: number;
  y: number;
}

export const isPointInRect = (area: Area, point: Point) =>
  point.x > area.left && point.x < area.right && point.y > area.top && point.y < area.bottom;
