import { Tabs, TabsProps } from 'antd';
import './styles.less';
import { Icon } from 'components';
import { useTranslation } from 'react-i18next';
import { ListDocumentsTrash } from './trash';
import { ListDocuments } from './documents';
import { NewDocumentButton } from '../new-document-button';
import { useState } from 'react';

export function PipelineTabs() {
  const { t } = useTranslation();

  const tabsKeys = {
    DOCUMENTS: 'documents',
    TRASH: 'trash',
  };

  const [currentTab, setCurrentTab] = useState(tabsKeys.DOCUMENTS);

  const items: TabsProps['items'] = [
    {
      key: tabsKeys.DOCUMENTS,
      label: (
        <>
          {<Icon name="IcoDocuments" />}
          {t('pipeline.tabs.document_list.title')}
        </>
      ),
      children: <ListDocuments />,
    },

    {
      key: tabsKeys.TRASH,
      label: (
        <>
          {<Icon name="IcoTrash" />}
          {t('pipeline.tabs.trash_document_list.title')}
        </>
      ),
      children: <ListDocumentsTrash />,
    },
  ];

  return (
    <div className="pipeline-tabs">
      <Tabs
        tabPosition={'left'}
        items={items}
        destroyInactiveTabPane={true}
        activeKey={currentTab}
        onChange={(activeTab) => setCurrentTab(activeTab)}
        tabBarExtraContent={{ left: currentTab !== tabsKeys.TRASH ? <NewDocumentButton /> : null }} //left works as 'top' since we are using tabPosition left
      />
    </div>
  );
}
