import { Heading } from 'components/typography';

export function Salesforce() {
  return (
    <>
      <Heading level={5}>Salesforce Settings</Heading>
      <div>Coming Soon</div>
    </>
  );
}
