import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { usePreviewSignatures } from 'hooks/usePreviewSignatures';
import { useParams } from 'react-router-dom';
import { useProposalContent } from '../../hooks/useProposalContent';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import WithSignatureModal from 'components/editor/signatures/withSignatureModal';
import SignedSignature from 'components/editor/signatures/signedSignature';
import WithLoadingIndicator from 'components/page-loader/page-loader';

import './styles.less';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';

import { editorConfig } from 'components/editor/helpers/config';
import { useTranslation } from 'react-i18next';
import { SignatureStatus } from 'services/repositories/interfaces/SignatureRepository';
import { PreviewMenu } from 'components/preview/preview-menu';
import { CINDER_URLS } from 'utils/cinder-urls';
import { Preview } from 'components/preview';
import { NavigateToCinder } from 'components/navigate-to-cinder';
import Banner from 'components/preview/banner';
import { Col, Row } from 'antd';
import { isAuthenticated } from 'utils/authentication';
import { PreviewActionMenu } from 'components/preview/action-menu/action-menu';

export const PreviewPage: React.FC = () => {
  const { t } = useTranslation();
  const { documentId: documentIdFromParam } = useParams<{ documentId: string }>();
  const documentId = documentIdFromParam as string;
  const { data: documentData, status } = useGetDocumentByIdQuery(documentId);
  const { data: proposalContent } = useProposalContent(documentId);
  const { signatures, setSignatures } = usePreviewSignatures(documentId);
  const documentContent = proposalContent?.content;

  const documentTitle = documentData?.title || 'Untitled Document';
  const documentPrice = documentData?.price || 0.0;
  const documentProspectName = documentData?.prospect?.name || '';

  switch (status) {
    case 'error':
      return <NavigateToCinder path={CINDER_URLS.error} />;
    case 'success':
      return (
        <Preview
          header={
            <PreviewMenu
              documentId={documentId}
              documentTitle={documentTitle}
              documentProspectName={documentProspectName}
              documentPrice={documentPrice}
            />
          }
          banner={<Banner documentId={documentId}>{t('document.preview.banner')}</Banner>}
          main={
            <div className="preview__editor__wrapper">
              <FroalaEditor
                model={documentContent}
                config={{
                  ...editorConfig,
                  editorClass: 'py-preview',
                  events: {
                    initialized() {
                      (this as any).edit.off();
                    },
                  },
                }}
              />
              <div className="preview__signatures">
                <div className="preview__signatures__container">
                  {Array.isArray(signatures) &&
                    signatures?.map(({ signatureBoxId, properties, ...rest }) => {
                      return rest.status === SignatureStatus.Signed ? (
                        <SignedSignature
                          signee={rest.signatureEvent}
                          position={properties.position}
                          dimensions={properties.dimensions}
                          signatureId={signatureBoxId}
                          key={signatureBoxId}
                          signedDate={rest.signatureEvent.signedDate}
                        />
                      ) : (
                        <WithSignatureModal
                          position={properties.position}
                          dimensions={properties.dimensions}
                          signatureId={signatureBoxId}
                          key={signatureBoxId}
                          assignedSignee={rest.assignedSignee}
                          documentId={documentId}
                          setSignatures={setSignatures}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          }
          footer={
            <Row justify="space-between" align="middle">
              <Col>
                <div className="preview__footer__document_price">
                  {documentData.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </div>
              </Col>
              {isAuthenticated() ? (
                <Col>
                  <PreviewActionMenu documentId={documentId} documentTitle={documentTitle} documentProspectName={documentProspectName} />
                </Col>
              ) : null}
            </Row>
          }
        />
      );
    case 'loading':
    default:
      return <WithLoadingIndicator isLoading={true} isLoaded={false}></WithLoadingIndicator>;
  }
};
