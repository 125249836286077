import { useReducer, useMemo } from 'react';

const initialState = {
  isVisible: false,
  title: '',
  features: [],
  planType: '',
  onCancel: () => {
    // do nothing
  },
  onConfirm: () => {
    // do nothing
  },
  confirmButtonLabel: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL_FEATURES_MODAL_CONTENT': {
      return {
        ...state,
        ...action.value,
      };
    }
    case 'SET_VISIBLE': {
      return {
        ...state,
        isVisible: action.value,
      };
    }
  }
};

export const useSeeAllFeaturesReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const triggerDispatch = ({ title, plan, features, onConfirm, confirmButtonLabel }) => {
    dispatch({
      type: 'SET_ALL_FEATURES_MODAL_CONTENT',
      value: {
        isVisible: true,
        title: `${title} plan features`,
        features: features,
        planType: plan,
        onCancel: () => {
          dispatch({
            type: 'SET_VISIBLE',
            value: false,
          });
        },
        onConfirm: onConfirm,
        confirmButtonLabel: confirmButtonLabel,
      },
    });
  };

  return useMemo(
    () => ({
      state,
      triggerDispatch,
    }),
    [state, dispatch]
  );
};
