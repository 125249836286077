import { List } from 'antd';
import { Link } from 'react-router-dom';
import { URLS } from 'utils/urls';
import './styles.less';
import usePreviewUrl from '../../../editor/usePreviewUrl';
import { ReactNode, useEffect, useState } from 'react';
import { Document } from 'services/documents/entities/Document';
import { NotifyObject, listUndoObserver } from '../listUndoObserver';
import { DuplicateDocumentAction } from './listActionItems/DuplicateDocumentAction';
import { TrashDocumentAction } from './listActionItems/TrashDocumentAction';

export type ListType = 'Documents' | 'Trash';

export type Props = {
  type: ListType;
  documentList: Document[];
  setDocumentList: (documents: Document[] | ((prev: Document[]) => Document[])) => Promise<void>;
  isLoading: boolean;
  listEmptyMessage: ReactNode;
  action: {
    icon: ReactNode;
    message: string;
    tooltipText: string;
    onAction: (documentId: string) => Promise<void>;
    onUndo: (documentId: string) => Promise<void>;
    onDuplicate?: () => Promise<void>;
  };
};
export function PipelineList({ type, documentList, setDocumentList, listEmptyMessage, isLoading, action }: Props) {
  const [highlightItemId, setHighlightItemId] = useState('');
  const previewUrlPrefix = usePreviewUrl();

  useEffect(() => {
    const observerId = listUndoObserver.subscribe({
      action: removeDocumentWhenUndoFromAnotherTab,
    });

    return () => {
      listUndoObserver.unsubscribe(observerId);
    };
  }, []);

  const removeDocumentWhenUndoFromAnotherTab = ({ listType, documentId }: NotifyObject) => {
    if (listType !== type) {
      setDocumentList((previousList) => previousList.filter((document) => document.id !== documentId));
    }
  };

  const handleOnListMouseEnter = () => {
    if (!highlightItemId) {
      return;
    }
    setHighlightItemId('');
  };

  const scrollToNewlyDuplicatedDocument = (itemId: string) => {
    const targetDocument = document.getElementById(itemId);
    if (targetDocument) {
      targetDocument.scrollIntoView();
    }
  };

  return (
    <List
      className="pipeline__list"
      loading={isLoading}
      dataSource={documentList}
      locale={{ emptyText: <div className="pipeline-empty-list">{listEmptyMessage}</div> }}
      renderItem={(item) => {
        const destination = item.isLocked ? previewUrlPrefix + item.id : URLS.editDocument(item.id);
        const isEditorv3 = item.editorVersion === 3;
        let clientName = item.companyName;
        if (item.prospectFirstName && item.prospectLastName) {
          clientName = item.prospectFirstName + ' ' + item.prospectLastName;
        }

        let assignedUser = '';
        if (item.assigneeUserFirstName && item.assigneeUserLastName) {
          assignedUser = item.assigneeUserFirstName + ' ' + item.assigneeUserLastName;
        }

        return (
          <Link
            to={destination}
            key={item.id}
            id={item.id}
            data-testid={`pipelineItem${item.id}`}
            className={`document-version-${item.editorVersion}`}
          >
            <List.Item className={highlightItemId === item.id ? 'item-highlight' : ''} onMouseEnter={handleOnListMouseEnter}>
              {isEditorv3 ? <div className="v3-beta-indicator">Beta</div> : null}
              <List.Item.Meta title={item.title} description={clientName ?? '-'}></List.Item.Meta>
              <div className="assigned_user">{assignedUser}</div>
              {type === 'Documents' && isEditorv3 ? (
                <DuplicateDocumentAction
                  documentForDuplicate={item}
                  setDocumentList={setDocumentList}
                  onDuplicate={action.onDuplicate}
                  setHighlightItemId={setHighlightItemId}
                  scrollToNewlyDuplicatedDocument={scrollToNewlyDuplicatedDocument}
                />
              ) : null}
              {isEditorv3 ? (
                <TrashDocumentAction
                  listType={type}
                  documentList={documentList}
                  documentToTrash={item}
                  setDocumentList={setDocumentList}
                  action={action}
                  isLoading={isLoading}
                />
              ) : null}
            </List.Item>
          </Link>
        );
      }}
    />
  );
}
