import React, { useState, useCallback } from 'react';
import { useCurrentUser } from 'providers/UserProvider';
import { Heading, Button, Icon } from 'components';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, List } from 'antd';
import { teamTopFeatures, businessTopFeatures, allPlanFeatures } from './planFeatures';
import FeatureTable from './featureTable';
import FAQ from './faq';
import BillingHeader from './billingHeader';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { PlanPageProps, PlanCardType } from './interface';
import { usePlanApiData } from 'hooks/usePlanApiData';
import DowngradeModal from './DowngradeModal';
import PageLoader from 'components/page-loader/page-loader';
import SeeAllFeaturesModal from './seeAllFeaturesModal';
import { useSeeAllFeaturesReducer } from 'hooks/useSeeAllFeaturesReducer';
import { ApiPlanFeatureRepository } from 'services/repositories/implementations/ApiPlanFeatureRepository';
import goToCinder from 'utils/goToCinder';
import './styles.less';

const PlanPage: React.FC<PlanPageProps> = ({ planRepository }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    data: { accountId },
  } = useCurrentUser();
  const { planData, isLoading, isFetched } = usePlanApiData({ planRepository, accountId, enabled: !!accountId });
  const apiPlanFeatureRepository = new ApiPlanFeatureRepository();

  const { t } = useTranslation();
  const { isDesktop, isTablet, isMobile } = useMediaQuery();
  const translatedTeamTopFeatures = teamTopFeatures.map((feature) => t(feature));
  const translatedBusinessTopFeatures = businessTopFeatures.map((feature) => t(feature));

  const { state: seeAllFeaturesState, triggerDispatch: seeAllFeaturesDispatch } = useSeeAllFeaturesReducer();

  const handleTalkToSales = () => window.open('https://links.proposify.com/sales/book');

  const handleViewPlanDetails = () => goToCinder('settings/summary');

  const handleBusinessButtonClick = useCallback(() => {
    if (planData?.type === 'business') {
      handleViewPlanDetails();
    } else {
      handleTalkToSales();
    }
  }, [planData?.type]);

  const handleTeamButtonClick = useCallback(() => {
    if (planData?.type === 'team') {
      handleViewPlanDetails();
    } else {
      handleDowngrade();
    }
  }, [planData?.type]);

  const handleDowngrade = useCallback(() => {
    if (!planData?.isSwitchingToTeamPlanADowngrade) {
      goToCinder('settings/plan/team');
    } else if (!planData?.canSelfDowngrade && planData?.type !== '') {
      goToCinder('settings/request/downgrade');
    } else {
      setModalOpen(true);
    }
  }, [isModalOpen, planData?.canSelfDowngrade]);

  const teamPlanButtonLabel =
    planData?.type === 'team' ? 'settings.plan.button_view_plan_details' : 'settings.plan.button_downgrade_plan_label';

  const businessPlanButtonLabel =
    planData?.type === 'business' ? 'settings.plan.button_view_plan_details' : 'settings.plan.button_contact_plan_label';

  const handleSeeAllFeaturesButtonClick = (plan: PlanCardType) => {
    const features = {};
    const title = plan.charAt(0).toUpperCase() + plan.slice(1);

    for (const featureGroup in allPlanFeatures) {
      features[featureGroup] = allPlanFeatures[featureGroup].filter((feature) => feature[plan]);
    }

    const onConfirm = () => {
      if (plan === 'business') {
        handleBusinessButtonClick();

        return;
      }

      handleTeamButtonClick();
    };

    const confirmButtonLabel = plan === 'business' ? businessPlanButtonLabel : teamPlanButtonLabel;

    seeAllFeaturesDispatch({
      title,
      plan,
      features,
      onConfirm,
      confirmButtonLabel,
    });
  };

  const SeeAllFeaturesLink = ({ plan }: { plan: PlanCardType }) => {
    return (
      <a className="see-all-features-link" href="#" onClick={() => handleSeeAllFeaturesButtonClick(plan)}>
        {t('settings.plan.see_all_features')} <Icon name="IcoArrowRight" />
      </a>
    );
  };

  return (
    <PageLoader isLoading={isLoading} isLoaded={isFetched}>
      <BillingHeader />
      {planData?.id ? (
        <DowngradeModal
          planFeatureRepository={apiPlanFeatureRepository}
          isOpen={isModalOpen}
          setModalOpen={setModalOpen}
          currentPlanId={planData.id}
        />
      ) : null}
      <SeeAllFeaturesModal state={seeAllFeaturesState} />
      <Row className="plan-page-price-card-container">
        <Col md={{ span: 7, offset: 8 }} sm={12} xs={24}>
          <Card className="default team" bordered={false}>
            <Heading level={2}>{t('settings.plan.team.title')}</Heading>
            <p>
              {t('settings.plan.team.subtitle')}
              <br />
              {t('settings.plan.team.subtitle_billing_cycle')}
            </p>
            <div className="price">
              <Heading level={1}>$49</Heading>
              <p>{t('settings.plan.team.billing_model')}</p>
            </div>
            <List
              dataSource={translatedTeamTopFeatures}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta avatar={<Icon name="IcoCircleCheckSolid" />} description={item} />
                </List.Item>
              )}
            />
            {(isTablet || isMobile) && <SeeAllFeaturesLink plan="team" />}
            <Button type="default" variant="neutral" onClick={handleTeamButtonClick}>
              {t(`${teamPlanButtonLabel}`)}
            </Button>
          </Card>
        </Col>
        <Col className="emphasis-container" md={7} sm={12} xs={24}>
          <Card className="emphasis business" bordered={false}>
            <Heading level={2}>{t('settings.plan.business.title')}</Heading>
            <p>
              {t('settings.plan.business.subtitle')}
              <br />
              {t('settings.plan.business.subtitle_billing_cycle')}
            </p>
            <div className="price">
              <p>{t('settings.plan.starts_at')}</p>
              <Heading level={1}>$590</Heading>
              <p>
                {t('settings.plan.business.billing_model')}
                <br />
                {t('settings.plan.includes_users', { count: 10 })}
                <br />
              </p>
            </div>
            <p className="feature-pretext">{t('settings.plan.feature_pretext', { plan_title: 'Team' })}</p>
            <List
              dataSource={translatedBusinessTopFeatures}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta avatar={<Icon name="IcoCirclePlusSolid" />} description={item} />
                </List.Item>
              )}
            />
            {(isTablet || isMobile) && <SeeAllFeaturesLink plan="business" />}
            <Button type="default" variant="emphasis" onClick={handleBusinessButtonClick}>
              {t(`${businessPlanButtonLabel}`)}
            </Button>
          </Card>
        </Col>
      </Row>
      {isDesktop && (
        <Row className="feature-table">
          <Col span={20} offset={3} pull={1}>
            <FeatureTable features={allPlanFeatures} />
          </Col>
        </Row>
      )}
      <Row className="plan-page-faq-container">
        <Col span={24}>
          <FAQ />
        </Col>
      </Row>
    </PageLoader>
  );
};

export default PlanPage;
