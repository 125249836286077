import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ChangeEvent, useRef } from 'react';
import './ImageUploadButton.less';
import { editorConfig } from 'components/editor/helpers/config';
import { useParams } from 'react-router-dom';
import { useDocumentImageUploadMutation } from 'hooks/useDocumentImageMutation';
import { useTranslation } from 'react-i18next';
import { openNotification } from 'components/notification';
import { UploadDocumentLibraryImage } from 'services/repositories/interfaces/DocumentRepository';

export default function ImageUploadButton({ refreshImages }: any) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { documentId } = useParams<{ documentId: string }>();
  const { uploadImage } = useDocumentImageUploadMutation();
  const { t } = useTranslation();

  const handleClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];

    if (!file || !documentId) {
      return;
    }

    const formData = new FormData();
    formData.append('image', file);

    const payload: UploadDocumentLibraryImage = {
      documentId,
      image: formData,
    };

    uploadImage(payload, {
      onSuccess: async () => {
        openNotification({
          type: 'success',
          title: t('document.image.success'),
          description: '',
        });

        refreshImages();
      },
      onError: () => {
        openNotification({
          title: t('error'),
          description: t('document.menu.update_error'),
          type: 'error',
        });
      },
    });
  };

  return (
    <>
      <div className="upload-button-container" onClick={handleClick}>
        <AddCircleOutlineIcon className="upload-button-icon" />
      </div>
      <input type="file" onChange={onChangeHandler} ref={fileInputRef} accept={editorConfig.acceptedImageTypes.join(',')} hidden />
    </>
  );
}
