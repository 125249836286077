import {
  GetSignaturesByDocumentIdApiResponse,
  SignatureBox,
  SignatureRepository,
  AssignedSignedSignatureBox,
  Signed,
  Assigned,
  BaseSignatureBox,
  AssignedUnSignedSignatureBox,
  UnAssignedUnSignedSignatureBox,
  UnAssignedSignedSignatureBox,
  SignedSignatureBoxApiResponse,
  SignedSignatureBox,
  AssignedSignee,
  SignatureEvent,
  SignSignatureApiPayload,
  SignSignaturePayload,
  SignatureStatus,
  SignatureBoxApiResponse,
} from 'services/repositories/interfaces/SignatureRepository';
import { apiClient } from 'services/api';
import { Endpoints } from 'utils/endpoints';

export const transformSignatureBoxApiResponse = ({
  signature_box_id,
  type,
  properties,
  ...signatureBoxApi
}: SignatureBoxApiResponse): SignatureBox => {
  const base: BaseSignatureBox = {
    signatureBoxId: signature_box_id,
    properties,
    type,
  };

  if (signatureBoxApi.status === SignatureStatus.Signed) {
    const { signature_event } = signatureBoxApi;
    const { first_name, last_name, email, id, initials, ip_address, signed_date } = signature_event;
    const signed: Signed = {
      status: signatureBoxApi.status,
      signatureEvent: {
        id,
        email,
        initials,
        firstName: first_name,
        lastName: last_name,
        signedDate: signed_date,
        ipAddress: ip_address,
      },
    };

    if (signatureBoxApi.assigned_signee != null) {
      const { first_name, last_name, email, type, id } = signatureBoxApi.assigned_signee;
      const assigned: Assigned = {
        assignedSignee: { firstName: first_name, lastName: last_name, email, type, id },
      };
      const assignedAndSignedSignatureBox: AssignedSignedSignatureBox = {
        ...base,
        ...signed,
        ...assigned,
      };
      return assignedAndSignedSignatureBox;
    }

    const unAssignedAndSignedSignatureBox: UnAssignedSignedSignatureBox = {
      ...base,
      ...signed,
      assignedSignee: signatureBoxApi.assigned_signee,
    };
    return unAssignedAndSignedSignatureBox;
  }

  if (signatureBoxApi.assigned_signee != null) {
    const { first_name, last_name, email, type, id } = signatureBoxApi.assigned_signee;
    const assigned: Assigned = {
      assignedSignee: { firstName: first_name, lastName: last_name, email, type, id },
    };
    const assignedAndUnSigned: AssignedUnSignedSignatureBox = {
      ...base,
      ...assigned,
      status: signatureBoxApi.status,
      signatureEvent: signatureBoxApi.signature_event,
    };

    return assignedAndUnSigned;
  }

  const unAssignedAndUnsigned: UnAssignedUnSignedSignatureBox = {
    ...base,
    status: signatureBoxApi.status,
    assignedSignee: signatureBoxApi.assigned_signee,
    signatureEvent: signatureBoxApi.signature_event,
  };
  return unAssignedAndUnsigned;
};

export class ApiSignatureRepository implements SignatureRepository {
  public async signSignature(param: SignSignaturePayload) {
    const payload: SignSignatureApiPayload = {
      document_id: param.documentId,
      email: param.email,
      first_name: param.firstName,
      last_name: param.lastName,
      initials: param.initials,
      signature_box_id: param.signatureBoxId,
    };
    const response = await apiClient.post<SignedSignatureBoxApiResponse>(Endpoints.signSignature(), payload);
    const { assigned_signee, properties, signature_box_id, signature_event, status, type } = response;

    let assignedSignee: AssignedSignee | null = null;
    if (assigned_signee) {
      assignedSignee = {
        email: assigned_signee.email,
        firstName: assigned_signee.first_name,
        lastName: assigned_signee.last_name,
        type: assigned_signee.type,
        id: assigned_signee.id,
      };
    }

    const { email, first_name, id, initials, ip_address, last_name, signed_date } = signature_event;
    const signatureEvent: SignatureEvent = {
      email,
      id,
      initials,
      firstName: first_name,
      lastName: last_name,
      ipAddress: ip_address,
      signedDate: signed_date,
    };

    const data: SignedSignatureBox = {
      status,
      type,
      properties,
      signatureBoxId: signature_box_id,
      assignedSignee,
      signatureEvent,
    };

    return data;
  }

  public async getSignaturesByDocumentId(documentId) {
    const { signatures } = await apiClient.get<GetSignaturesByDocumentIdApiResponse>(Endpoints.getSignaturesByDocumentId(documentId));

    return {
      signatures: signatures.map((signature): SignatureBox => {
        return transformSignatureBoxApiResponse(signature);
      }),
    };
  }
}
