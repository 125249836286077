import React, { useEffect, useState, useContext, useRef } from 'react';
import { VariablesGroup } from '../../services/entities/VariablesEntity';
import './documentBuilder.less';
import './variables/variablesList.less';
import { SaveStatus } from 'providers/SignaturesProvider';
import { Layout } from 'antd';
import { SocketClientInterface } from 'services/socket/SocketClient';
import { EditorMenu } from './editor-menu';
import FontLoader from '../font';
import { accessToken } from 'services/authorization/handleTokenCookie';
import useEditorRef from 'hooks/useEditorRef';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import PageLoader from 'components/page-loader/page-loader';
import { EditorSidePanel } from './sidepanel';
import DocumentLockedModal from './modals/DocumentLockedModal';
import { URLS } from 'utils/urls';
import { ErrorCode } from 'services/socket/SocketEvents';
import { useNavigate } from 'react-router-dom';
import { DocumentSaveStatus } from './helpers/DocumentSaveStatus';
import { UsedVariable } from './sidepanel/variables/interfaces';
import Froala from 'froala-editor';
import { undoPlugin } from './custom-plugins/undo/undo.plugin';
import { FeatureFlags } from 'utils/featureFlags';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { EditorContent } from './editor-content';
import { SignaturesContext } from 'providers/SignaturesProvider';
import { UsedVariablesContext } from '../../providers/UsedVariablesContext';
import { editorConfig as defaultEditorConfig } from './helpers/config';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import usePreviewRedirect from '../../pages/editor/usePreviewRedirect';
import { setInitialState } from './grid/reduxStore/editorSlice';
import { useDispatch } from 'react-redux';
import { GridAddType } from './grid/reduxStore/saveReducers';
import { NavigateToCinder } from 'components/navigate-to-cinder';
import { CINDER_URLS } from 'utils/cinder-urls';
import { convertGridResponseToGridContentBlocks } from './GridDndEditor/gridHelper';

const { Sider } = Layout;

interface DocumentBuilderProps {
  variables?: VariablesGroup;
  documentId: string;
  socketClient: SocketClientInterface;
}
const SIDEPANEL_VERTICAL_NAV_TAB_WIDTH = 40;
const SIDEPANEL_CONTENT_WIDTH = 264;
const siderWidth = SIDEPANEL_VERTICAL_NAV_TAB_WIDTH + SIDEPANEL_CONTENT_WIDTH;

const DocumentBuilder: React.FC<DocumentBuilderProps> = ({ variables, documentId, socketClient }) => {
  const {
    data: documentData,
    isFetching,
    isFetched,
    isFetchedAfterMount,
    status: documentLoadingStatus,
  } = useGetDocumentByIdQuery(documentId);
  const [contentSaveStatus, setContentSaveStatus] = useState<SaveStatus>({ status: DocumentSaveStatus.SAVED, errCode: '' });
  const [usedVariables, setUsedVariables] = useState<UsedVariable[]>([]);
  const [editorConfig, setEditorConfig] = useState<any>(undefined);
  const [isConfigLoaded, setIsConfigLoaded] = useState<boolean>(false);
  const toolbarButtons = useRef<string[]>([]);
  const { signatureSaveStatus, setSignaturesOnMount } = useContext(SignaturesContext);
  const { editorRef, setRef } = useEditorRef();
  const [initialDocContent, setInitialDocContent] = useState<string | undefined>(undefined);
  const [isSignatureLoaded, setIsSignatureLoaded] = useState<boolean>(false);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const { usedVariables: usedVariablesArray } = useContext(UsedVariablesContext);
  const navigate = useNavigate();
  const handlePreviewRedirect = usePreviewRedirect(documentId);
  const handlePipelineRedirect = () => navigate(URLS.pipeline);
  const evaluatedFeatureFlags = useFeatureFlag([FeatureFlags.centralizedUndoRedo, FeatureFlags.snapToGrid]);
  const dispatch = useDispatch();

  useEffect(() => {
    const uniqueNames = new Set<string>();
    const mergedArray: UsedVariable[] = [];

    Object.entries(usedVariablesArray).forEach(([, value]) => {
      value.forEach((variable) => {
        if (!uniqueNames.has(variable.name)) {
          uniqueNames.add(variable.name);
          mergedArray.push(variable);
        }
      });
    });

    setUsedVariables(mergedArray);
  }, [usedVariablesArray]);

  const editorConfigHandler = (newConfig) => {
    setEditorConfig(newConfig);
    setIsConfigLoaded(true);
  };

  useEffect(() => {
    if (signatureSaveStatus.status !== contentSaveStatus.status) {
      setContentSaveStatus(signatureSaveStatus);
    }
  }, [signatureSaveStatus.status, signatureSaveStatus.errCode]);

  const gridLoadCallback = (data: string) => {
    const { content: gridContents }: { content: GridAddType[] } = JSON.parse(data);
    const gridBlockContents = convertGridResponseToGridContentBlocks(gridContents);
    dispatch(setInitialState({ documentId, gridBlocks: gridBlockContents }));
  };

  useEffect(() => {
    const editorUrl = process.env.REACT_APP_EDITOR_SERVER as string;
    socketClient.connect(editorUrl, { documentId, accessToken });

    return () => {
      socketClient.disconnect();
    };
  }, []);

  useEffect(() => {
    socketClient.getAllSignaturesOnMount((data) => {
      setSignaturesOnMount(data);
      setIsSignatureLoaded(true);
    });

    if (evaluatedFeatureFlags[FeatureFlags.snapToGrid] === true) {
      socketClient.getGridInitialContentLoad(gridLoadCallback);
    } else {
      socketClient.onContentGet((initialDataFromServer: string) => {
        setInitialDocContent(initialDataFromServer);
      });
    }
  }, [evaluatedFeatureFlags[FeatureFlags.snapToGrid], socketClient.getConnectionStatus() === true]);

  useEffect(() => {
    toolbarButtons.current = defaultEditorConfig['toolbarButtons'];
    if (evaluatedFeatureFlags[FeatureFlags.centralizedUndoRedo] === true) {
      toolbarButtons.current = defaultEditorConfig['toolbarButtons'].map((item) => {
        switch (item) {
          case 'undo':
            return 'centralizedUndo';
          case 'redo':
            return 'centralizedRedo';
          default:
            return item;
        }
      });

      Froala.PLUGINS.undoPlugin = undoPlugin;
      Froala.RegisterCommand('centralizedUndo', {
        title: 'undo',
        icon: 'undo',
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        callback: function () {
          this.undoPlugin.undo();
        },
        refresh: function ($btn) {
          $btn.toggleClass('fr-disabled', !this.undoPlugin.canUndo());
        },
      });
      Froala.RegisterCommand('centralizedRedo', {
        title: 'redo',
        icon: 'redo',
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        callback: function () {
          this.undoPlugin.redo();
        },
        refresh: function ($btn) {
          $btn.toggleClass('fr-disabled', !this.undoPlugin.canRedo());
        },
      });
    }
  }, [evaluatedFeatureFlags]);

  if (documentLoadingStatus === 'error') {
    return <NavigateToCinder path={CINDER_URLS.error} />;
  }
  return (
    <PageLoader isLoading={isFetching} isLoaded={isFetched && isFetchedAfterMount}>
      <DocumentLockedModal
        isDocumentLocked={
          documentData?.isLocked ||
          signatureSaveStatus.errCode === ErrorCode.LOCKED ||
          contentSaveStatus.errCode === ErrorCode.LOCKED ||
          false
        }
        handleOk={handlePreviewRedirect}
        handleCancel={handlePipelineRedirect}
      />
      <header className="editor-header-wrapper">
        <EditorMenu
          documentId={documentId}
          documentTitle={documentData?.title || 'Document Title'}
          price={documentData?.price || 0}
          status={documentData?.status || 'DRAFT'}
          documentStatus={contentSaveStatus.status}
          handleDocumentContentStatus={setContentSaveStatus}
        />
        <FontLoader editorConfigHandler={editorConfigHandler} />
      </header>
      <DndProvider backend={HTML5Backend}>
        <Layout className="editor-document-builder">
          <Layout className="editor-document-main-content">
            {isSignatureLoaded && isConfigLoaded && (
              <EditorContent
                documentId={documentId}
                socketClient={socketClient}
                variables={variables}
                editorRef={editorRef}
                setRef={setRef}
                setUsedVariables={setUsedVariables}
                editorConfig={editorConfig}
                setContentSaveStatus={setContentSaveStatus}
                initialDocContent={initialDocContent}
                gridRef={gridRef}
              />
            )}
          </Layout>
          <Sider theme="light" width={siderWidth} className="editor-right-sider">
            <EditorSidePanel documentId={documentId} editorRef={editorRef} usedVariables={usedVariables} gridRef={gridRef} />
          </Sider>
        </Layout>
      </DndProvider>
    </PageLoader>
  );
};

export default DocumentBuilder;
