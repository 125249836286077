import React, { createContext, useCallback, useState } from 'react';
import { UsedVariable } from '../components/editor/sidepanel/variables/interfaces';

interface UsedVariablesContextProps {
  usedVariables: { [key: string]: Set<UsedVariable> };
  handleUsedVariables: (gridBlockId: string, variables: UsedVariable[]) => void;
}

export const UsedVariablesContext = createContext<UsedVariablesContextProps>({
  usedVariables: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleUsedVariables: () => {},
});

export const UsedVariablesProvider: React.FC = ({ children }) => {
  const [usedVariables, setUsedVariables] = useState<{ [key: string]: Set<UsedVariable> }>({});

  const handleUsedVariables = useCallback(
    (gridBlockId: string, variables: UsedVariable[]) => {
      const updatedUsedVariables = { ...usedVariables };
      updatedUsedVariables[gridBlockId] = new Set(variables);
      setUsedVariables(updatedUsedVariables);
    },
    [usedVariables, setUsedVariables]
  );

  return <UsedVariablesContext.Provider value={{ usedVariables, handleUsedVariables }}>{children}</UsedVariablesContext.Provider>;
};
