import { useMutation, useQueryClient } from 'react-query';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';
import { QueryKeys } from 'utils/queryKeys';

export default function useUpdateDocumentMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: apiDocumentRepository.updateDocument,
    onSuccess: async (_, payload) => await queryClient.invalidateQueries(QueryKeys.getDocumentById(payload.documentId)),
  });
}
