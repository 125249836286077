import React from 'react';
import { Paragraph } from '../../../components';
import { Icons } from '../../icon/icons';
import './blockSourceButton.less';

const { IcoDrag } = Icons;

interface BlockSourceButtonProps {
  title: string;
  handleDragStart?: any;
  handleClick?: any;
  handleDragEnd?: any;
  icon: any;
  customClassName?: string;
}

const BlockSourceButton: React.FunctionComponent<BlockSourceButtonProps> = ({
  title,
  icon,
  customClassName,
  handleClick,
  handleDragEnd,
  handleDragStart,
}) => {
  const className = customClassName ? `block-source ${customClassName}` : 'block-source';

  return (
    <>
      <button
        data-type="text"
        className={className}
        draggable
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={handleClick}
      >
        <div className="block-source-icon">{icon}</div>
        <div className="block-source-drag-icon">
          <IcoDrag />
        </div>
        <Paragraph size="sm">{title}</Paragraph>
      </button>
    </>
  );
};

export default BlockSourceButton;
