import { useState, forwardRef, useCallback, useContext, useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { VariablesGroup } from 'services/entities/VariablesEntity';
import { GridBlockContentChangeHandler } from '../grid/editor-wrapper';
import './style.less';
import '../../../styles/tokens.less';
import { SignaturesContext, SignatureSocketWriteOperationPayload } from '../../../providers/SignaturesProvider';
import { getSignaturesMaxHeight } from '../sidepanel/signatures/signature-helper';
import { GridBlockContent } from '../grid/reduxStore/editorSlice';
import { EditorGridBlock } from './EditorGridBlock';
import { GridImageLoader } from './GridImageLoader';
import { AddBlock } from './models/addBlock.model';
import { Block } from './models/block.model';
import { GridBlockType } from '../shared/gridBlockType';
import { gridPageMaxWidthInPixels, gridPageMinHeightInPixels, gridPixelSize } from '../shared/gridConfig';
import { DraggableGridBlockWrapper } from './DraggableGridBlockWrapper';
import { SignatureStatus } from 'services/repositories/interfaces/SignatureRepository';
import SignedSignature from '../signatures/signedSignature';
import UnSignedSignature from '../signatures/unsignedSignature';
import DragLines from './DragLines/DragLines';

export type GridBlockAddHandler = (documentContent: string, blockConfig: AddBlock, type: string) => void;
export type GridBlockChangeHandler = (gridBlockId: string, documentContent: string) => void;
export type GridBlockPositionChangeHandler = (gridBlockId: string, x: number, y: number) => void;
export type GridBlockDimensionChangeHandler = (gridBlockId: string, width: number, height: number) => void;

export interface Props {
  documentId: string;
  configOptions?: object | undefined;
  variables?: VariablesGroup;
  gridBlocks: GridBlockContent[];
  gridRef: React.MutableRefObject<HTMLDivElement | null>;
  gridBlockAddHandler?: GridBlockAddHandler;
  gridBlockChangeHandler?: GridBlockChangeHandler;
  gridBlockPositionChangeHandler?: GridBlockPositionChangeHandler;
  gridBlockDimensionChangeHandler?: GridBlockDimensionChangeHandler;
}

function setupTrackedBlocks(gridBlocks, signatures) {
  const collection = {};
  gridBlocks?.forEach((gridBlock) => {
    collection[gridBlock.blockConfig.id] = {
      height: gridBlock.blockConfig.height,
      width: gridBlock.blockConfig.width,
      x: gridBlock.blockConfig.x,
      y: gridBlock.blockConfig.y,
    };
  });

  signatures?.forEach((signature) => {
    collection[signature.signatureBoxId] = {
      height: signature.properties.dimensions.height,
      width: signature.properties.dimensions.width,
      x: signature.properties.position.x,
      y: signature.properties.position.y,
    };
  });

  return collection;
}

export const GridDndEditor = forwardRef<HTMLDivElement, Props>(
  ({
    configOptions,
    gridBlocks,
    gridRef,
    variables,
    gridBlockAddHandler,
    gridBlockChangeHandler,
    gridBlockPositionChangeHandler,
    gridBlockDimensionChangeHandler,
    documentId,
  }) => {
    const [maxPageHeight, setMaxPageHeight] = useState<number>(gridPageMinHeightInPixels);
    const { handleSignatureInsert, signatures, setSelectedSignature } = useContext(SignaturesContext);
    const [hoveredBlocksIds, setHoveredBlocksIds] = useState<string[]>([]);
    const [currentDraggedBlock, setCurrentDraggedBlock] = useState<Block | null>(null);
    let width = gridPageMaxWidthInPixels / 2;

    const getTrackedBlocks = useMemo(() => {
      return setupTrackedBlocks(gridBlocks, signatures);
    }, [gridBlocks?.length, signatures?.length]);

    const [, drop] = useDrop(() => ({
      accept: [GridBlockType.TEXT, GridBlockType.SIGNATURE],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: (item, monitor) => {
        const offset = monitor.getClientOffset();
        if (offset && gridRef.current) {
          const boundingBox = gridRef.current.getBoundingClientRect();
          const x = offset.x - boundingBox.x;
          const y = offset.y - boundingBox.y;
          const itemType = monitor.getItemType();
          width = gridPageMaxWidthInPixels - x;

          if (itemType === GridBlockType.TEXT) {
            if (gridBlockAddHandler) {
              gridBlockAddHandler(
                '',
                {
                  x: x,
                  y: y,
                  width: width,
                },
                'add'
              );
            }
          } else if (itemType === GridBlockType.SIGNATURE && item) {
            const signatureItem = item as SignatureSocketWriteOperationPayload;
            const payload: SignatureSocketWriteOperationPayload = {
              ...signatureItem,
              properties: {
                dimensions: signatureItem.properties.dimensions,
                position: {
                  x: x,
                  y: y,
                },
              },
            };
            handleSignatureInsert(payload);
          }
        }
      },
    }));

    function combinedRef(el) {
      drop(el);
      if (el) {
        gridRef.current = el;
      }
    }

    const editorResizeHandler = (editorId: string, rect: DOMRectReadOnly) => {
      gridBlocks
        .map((gridBlock) => gridBlock.blockConfig)
        .filter((block) => block.id === editorId)
        .forEach((block) => {
          if (block.height === rect.height) return;
          if (gridBlockDimensionChangeHandler) {
            gridBlockDimensionChangeHandler(block.id, block.width || 0, rect.height);
          }
        });
      const computedMaxHeight = calculateEditorHeight(gridBlocks);
      setMaxPageHeight(computedMaxHeight);
    };

    const calculateEditorHeight = (blocks: GridBlockContent[]) => {
      const maxY = blocks
        .map((gridBlocks) => gridBlocks.blockConfig)
        .reduce((max, block) => Math.max(max, block.y + (block.height || 0)), 0);

      const signMaxHeight = signatures ? getSignaturesMaxHeight(signatures) : 0;
      const editorMaxHeight = Math.max(signMaxHeight, maxY);
      return editorMaxHeight + gridPixelSize * gridPixelSize;
    };

    const handleStop = (e, data, item: Block) => {
      setCurrentDraggedBlock(null);
      const { x, y } = data;

      // Round the x and y positions to the nearest multiple of gridSize
      const roundedX = Math.round(x / gridPixelSize) * gridPixelSize;
      const roundedY = Math.round(y / gridPixelSize) * gridPixelSize;

      gridBlocks
        .filter((block) => block.id === item.id)
        .map((gridBlock) => gridBlock.blockConfig)
        .forEach((block) => {
          if (block.id === item.id) {
            if (gridBlockPositionChangeHandler) {
              gridBlockPositionChangeHandler(block.id, roundedX, roundedY);
            }
          }
        });
    };

    const onResizeStop = (e, direction, ref, delta, item: Block) => {
      const { width, height } = ref.style;
      const parsedWidth = parseInt(width, 10);
      const parsedHeight = parseInt(height, 10);

      gridBlocks
        .filter((block) => block.id === item.id)
        .map((block) => block.blockConfig)
        .forEach((block) => {
          if (gridBlockDimensionChangeHandler) {
            gridBlockDimensionChangeHandler(block.id, parsedWidth, parsedHeight);
          }
        });
    };

    const computedMaxHeight = calculateEditorHeight(gridBlocks);
    useEffect(() => {
      setMaxPageHeight(computedMaxHeight);
    }, [computedMaxHeight]);

    const handleMouseEnter = useCallback((blockId: string) => {
      setHoveredBlocksIds((prev) => [...prev, blockId]);
      return true;
    }, []);

    const handleMouseLeave = (blockId: string) => {
      setHoveredBlocksIds((prev) => prev.filter((id) => id !== blockId));
    };

    const blockContentChangeHandler: GridBlockContentChangeHandler = (gridBlockId: string, blockContent: string) => {
      const gridBlockContent = gridBlocks.find((block: GridBlockContent) => block.id === gridBlockId);
      if (gridBlockChangeHandler && gridBlockContent) {
        gridBlockChangeHandler(gridBlockId, blockContent);
      }
    };

    const handleDrag = (e, draggedBlock: Block) => {
      setCurrentDraggedBlock(draggedBlock);
    };

    const maxHeight = Math.max(maxPageHeight, gridPageMinHeightInPixels);

    return (
      <div className="editor__page" ref={combinedRef} style={{ height: maxHeight }}>
        <DragLines
          currentBlock={currentDraggedBlock}
          trackedBlockCollection={getTrackedBlocks}
          maxWidthPx={gridPageMaxWidthInPixels}
          maxHeightPx={maxHeight}
        />
        <GridImageLoader
          documentId={documentId}
          gridBlocks={gridBlocks}
          handleDrag={handleDrag}
          handleStop={handleStop}
          onResizeStop={onResizeStop}
          gridPixelSize={gridPixelSize}
        />
        {gridBlocks
          .filter((gridBlock) => gridBlock.type === GridBlockType.TEXT)
          .map((block, index) => (
            <div
              key={block.id}
              className={`grid_block_${block.id}`}
              onMouseEnter={() => handleMouseEnter(block.id)}
              onMouseLeave={() => handleMouseLeave(block.id)}
            >
              <DraggableGridBlockWrapper
                key={`grid-text-${index}`}
                gridBlock={block}
                gridSystemInPx={gridPixelSize}
                handleStop={handleStop}
                handleDrag={handleDrag}
                handleResizeStop={onResizeStop}
              >
                <EditorGridBlock
                  block={block}
                  configOptions={configOptions}
                  variables={variables}
                  hoveredBlocksIds={hoveredBlocksIds}
                  blockContentChangeHandler={blockContentChangeHandler}
                  editorResizeHandler={editorResizeHandler}
                />
              </DraggableGridBlockWrapper>
            </div>
          ))}
        {signatures?.map(({ signatureBoxId, properties, status, signatureEvent, assignedSignee }) => {
          return status === SignatureStatus.Signed ? (
            <SignedSignature
              signee={signatureEvent}
              position={properties.position}
              dimensions={properties.dimensions}
              signatureId={signatureBoxId}
              key={signatureBoxId}
              signedDate={signatureEvent.signedDate}
              bounds=".editor__page"
              onDrag={handleDrag}
            />
          ) : (
            <UnSignedSignature
              assignedSignee={assignedSignee}
              position={properties.position}
              dimensions={properties.dimensions}
              signatureId={signatureBoxId}
              key={signatureBoxId}
              handleClick={() => setSelectedSignature(signatureBoxId)}
              bounds=".editor__page"
              onDrag={handleDrag}
            />
          );
        })}
      </div>
    );
  }
);

GridDndEditor.displayName = 'GridDndEditor';
