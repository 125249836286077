import { Icon } from 'components';
import { useDocumentListQuery } from 'hooks/useDocumentListQuery';
import { PipelineList } from '../../list';
import { useTranslation } from 'react-i18next';
import { moveDocumentToTrash } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { restoreDocumentFromTrash } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Snackbar } from 'components/snackbar';
import { Button, Input } from 'antd';
import './styles.less';
import { SearchEmptyResult } from './search-empty-result';
import { trackEvents } from 'utils/tracking';

const TERM_QUERY_PARAM = 'term';

export function ListDocuments() {
  const { t } = useTranslation();

  const pagePathname = window.location.pathname;
  const newQueryParameters = new URLSearchParams();
  const [currentQueryParams, setQueryParams] = useSearchParams();
  const currentSearchTerm = decodeURIComponent(currentQueryParams.get(TERM_QUERY_PARAM) ?? '');

  const { documentList, isLoadingDocumentList, setDocumentList, refreshDocumentList } = useDocumentListQuery({
    filterTrash: false,
    filterTerm: currentSearchTerm,
  });

  useEffect(() => {
    return () => {
      const isRedirectingToAnotherPage = window.location.pathname !== pagePathname;
      if (isRedirectingToAnotherPage) {
        return;
      }
      newQueryParameters.delete(TERM_QUERY_PARAM);
      setQueryParams(newQueryParameters);
    };
  }, []);

  const handleSearch = (value: string) => {
    if (value === currentSearchTerm) {
      return;
    }

    if (value === '') {
      newQueryParameters.delete(TERM_QUERY_PARAM);
    } else {
      newQueryParameters.set(TERM_QUERY_PARAM, encodeURIComponent(value));
      trackEvents('documents_search', { searchTerm: value });
    }
    setQueryParams(newQueryParameters);
  };

  const handleMoveToTrashAction = async (documentId: string) => {
    await moveDocumentToTrash(documentId);
  };

  const handleMoveToTrashUndo = async (documentId: string) => {
    await restoreDocumentFromTrash(documentId);
  };

  const handleDuplicateActionUndo = async () => {
    await refreshDocumentList();
  };

  return (
    <>
      <div className="documents-list-search-bar-area">
        <Input.Search
          defaultValue={currentSearchTerm}
          addonBefore={<Button className="documents-list-search-button" icon={<Icon name="IcoSearch24Px" />} />}
          placeholder={t('pipeline.tabs.search.inputPlaceholder')}
          className="documents-list-search-bar"
          bordered={false}
          allowClear={{ clearIcon: <Button icon={<Icon name="IcoCloseX" />} /> }}
          onBlur={(event) => handleSearch(event.target.value)}
          onSearch={(event) => handleSearch(event)}
          data-testid="pipelineSearchInput"
        ></Input.Search>
      </div>
      {currentSearchTerm && (
        <Snackbar message={t('pipeline.tabs.search.searchResults', { count: documentList.length, term: currentSearchTerm })} />
      )}
      <PipelineList
        type="Documents"
        documentList={documentList}
        setDocumentList={setDocumentList}
        isLoading={isLoadingDocumentList}
        listEmptyMessage={currentSearchTerm ? <SearchEmptyResult /> : t('pipeline.tabs.document_list.list_empty')}
        action={{
          message: t('pipeline.tabs.document_list.action_message'),
          tooltipText: t('pipeline.tabs.document_list.action_tooltip'),
          onAction: handleMoveToTrashAction,
          onUndo: handleMoveToTrashUndo,
          onDuplicate: handleDuplicateActionUndo,
          icon: <Icon name="IcoTrash" />,
        }}
      />
    </>
  );
}
