import { useMutation, useQueryClient } from 'react-query';
import apiProposalRepository from '../services/repositories/implementations/ApiProposalRepository';
import { Endpoints } from '../utils/endpoints';

export function useImageUploadMutation() {
  const queryClient = useQueryClient();

  const queryMutation = useMutation({
    mutationFn: apiProposalRepository.imageUpload,
    onSuccess: async () => await queryClient.invalidateQueries(Endpoints.uploadImage),
  });

  return { uploadImage: queryMutation.mutate };
}
