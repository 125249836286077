import useFeatureFlag from '../../hooks/useFeatureFlag';
import { FeatureFlags } from '../../utils/featureFlags';
import { URLS } from '../../utils/urls';

const usePreviewUrl = () => {
  const evaluatedSnapToGridFlag = useFeatureFlag([FeatureFlags.snapToGrid]);
  return evaluatedSnapToGridFlag[FeatureFlags.snapToGrid] ? URLS.previewGridDocument('') : URLS.previewDocument('');
};

export default usePreviewUrl;
