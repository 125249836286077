import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { ApiEditorRepository } from '../services/repositories/implementations/ApiEditorRepository';

export function useGetVariables(documentId: string, options: { enabled?: boolean } = { enabled: true }) {
  const editorRepository = new ApiEditorRepository();
  const { data, isError } = useQuery(QueryKeys.variables, () => editorRepository.getVariables(documentId), { enabled: options.enabled });

  return {
    data,
    isError,
  };
}
