import { useState, useEffect } from 'react';

type PageRefType = {
  current: HTMLDivElement | null;
};

type UseHandlePageBreaksResult = {
  pageBreaks: number;
};

export const MAX_PAGE_HEIGHT = 1123;

export const useHandlePageBreaks = (pageRef: PageRefType): UseHandlePageBreaksResult => {
  const [pageBreaks, setPageBreaks] = useState<number>(0);

  const appendToPageBreaks = (pageElementHeight: number) => {
    const pages = pageElementHeight / MAX_PAGE_HEIGHT;
    const currentPageBreaks = pages % 1 === 0 ? pages - 1 : pages;

    setPageBreaks(~~currentPageBreaks);
  };

  useEffect(() => {
    if (!pageRef.current) return;

    const pageElement = pageRef.current;

    const resizeObserver = new ResizeObserver(() => {
      appendToPageBreaks(pageElement.offsetHeight || 0);
    });

    resizeObserver.observe(pageElement);

    return () => resizeObserver.disconnect();
  }, [pageRef.current, appendToPageBreaks]);

  return { pageBreaks };
};
