import React from 'react';
import { Modal } from 'components';
import { useTranslation } from 'react-i18next';

type DocumentLockedModalProps = {
  isDocumentLocked: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

const DocumentLockedModal: React.FC<DocumentLockedModalProps> = ({ isDocumentLocked, handleOk, handleCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isVisible={isDocumentLocked || false}
      handleOk={handleOk}
      handleCancel={handleCancel}
      affirmText={t('document.locked.go_to_preview')}
      cancelText={t('document.locked.go_to_pipeline')}
      title={t('document.locked.title')}
      closable={false}
      maskClosable={false}
      escClosable={false}
    >
      <p>{t('document.locked.message')}</p>
    </Modal>
  );
};

export default DocumentLockedModal;
