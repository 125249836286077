import { Button, Icon, Tooltip, openNotification } from 'components';
import { useTranslation } from 'react-i18next';
import { closeToast, openToast } from 'components/toast';
import { deleteDocument, duplicateDocument } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { Document } from 'services/documents/entities/Document';

export type Props = {
  setDocumentList: (documents: Document[] | ((prev: Document[]) => Document[])) => Promise<void>;
  documentForDuplicate: Document;
  setHighlightItemId: (highlightItemId: string) => void;
  onDuplicate?: () => Promise<void>;
  scrollToNewlyDuplicatedDocument: (newlyDuplicatedDocumentId: string) => void;
};

export function DuplicateDocumentAction({
  documentForDuplicate,
  setDocumentList,
  onDuplicate,
  setHighlightItemId,
  scrollToNewlyDuplicatedDocument,
}: Props) {
  const { t } = useTranslation();

  const handleDuplicateUndo = async (duplicatedDocumentId: string) => {
    try {
      await deleteDocument(duplicatedDocumentId);
      setDocumentList((prevDocumentList) => {
        return prevDocumentList.filter(({ id }) => id !== duplicatedDocumentId);
      });
      openToast({
        message: t('pipeline.tabs.document_list.duplicate_undo_success_message'),
      });
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('pipeline.error.undo_error_msg'),
        description: t('pipeline.error.error_description'),
        placement: 'top',
      });
    }
  };

  const duplicateDocumentOnList = async (event: React.MouseEvent<HTMLButtonElement>, documentForDuplicate: Document) => {
    event.preventDefault();
    const documentTitle = documentForDuplicate.title;
    try {
      openToast({
        message: t('pipeline.tabs.document_list.duplicate_started_message', { documentTitle: documentTitle }),
        durationInSeconds: 0,
      });

      const newlyDuplicatedDocumentId = await duplicateDocument(documentForDuplicate.id);
      if (onDuplicate) await onDuplicate();
      setHighlightItemId(newlyDuplicatedDocumentId);

      openToast({
        message: t('pipeline.tabs.document_list.duplicate_done_message', { documentTitle: documentTitle }),
        animationWhenReplacingToast: false,
        onUndo: async () => {
          await handleDuplicateUndo(newlyDuplicatedDocumentId);
        },
        onSecondaryAction: {
          text: t('toast.scroll'),
          onClick: () => scrollToNewlyDuplicatedDocument(newlyDuplicatedDocumentId),
        },
        onClose: () => setHighlightItemId(''),
      });
    } catch (error) {
      closeToast();
      openNotification({
        type: 'error',
        title: t('pipeline.error.duplicate_error_msg'),
        description: t('pipeline.error.error_description'),
        placement: 'top',
      });
    }
  };

  return (
    <Tooltip placement={'bottom'} title={t('pipeline.tabs.document_list.duplicate_tooltip')}>
      <Button
        icon={<Icon name="IcoDuplicate" />}
        variant="neutral"
        type="default"
        className="duplicate-button"
        data-testid="duplicate-button"
        onClick={(event) => {
          duplicateDocumentOnList(event, documentForDuplicate);
        }}
      ></Button>
    </Tooltip>
  );
}
