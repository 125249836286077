import useFeatureFlag from '../../hooks/useFeatureFlag';
import { FeatureFlags } from '../../utils/featureFlags';
import { useCallback } from 'react';
import { URLS } from '../../utils/urls';
import { useNavigate } from 'react-router-dom';

const usePreviewRedirect = (documentId: string) => {
  const evaluatedSnapToGridFlag = useFeatureFlag([FeatureFlags.snapToGrid]);
  const navigate = useNavigate();

  return useCallback(() => {
    if (evaluatedSnapToGridFlag[FeatureFlags.snapToGrid]) {
      navigate(URLS.previewGridDocument(documentId));
    } else {
      navigate(URLS.previewDocument(documentId));
    }
  }, [evaluatedSnapToGridFlag]);
};

export default usePreviewRedirect;
