import React, { FunctionComponent } from 'react';
import { Skeleton } from 'antd';

interface SkeletonLoadingProps {
  imageKey: number;
  xAxis: number;
  yAxis: number;
  width: number;
  height: number;
  className?: string;
}

const SkeletonImageLoading: FunctionComponent<SkeletonLoadingProps> = ({ imageKey, xAxis, yAxis, width, height, className }) => {
  const skeletonStyle: React.CSSProperties = {
    position: 'absolute',
    left: `${xAxis}px`,
    top: `${yAxis}px`,
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <div data-testid={`skeleton-image-loader-${imageKey}`}>
      <Skeleton.Image key={imageKey} data-testid="grid-image-loader" style={skeletonStyle} active={true} className={className} />
    </div>
  );
};

export default SkeletonImageLoading;
