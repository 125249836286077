import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { usePreviewSignatures } from 'hooks/usePreviewSignatures';
import { useParams } from 'react-router-dom';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import WithLoadingIndicator from 'components/page-loader/page-loader';

import './gridStyles.less';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';

import { editorConfig } from 'components/editor/helpers/config';
import { useTranslation } from 'react-i18next';
import { PreviewMenu } from 'components/preview/preview-menu';
import { CINDER_URLS } from 'utils/cinder-urls';
import { PreviewGrid } from 'components/preview';
import { NavigateToCinder } from 'components/navigate-to-cinder';
import Banner from 'components/preview/banner';
import { Col, Row } from 'antd';
import { isAuthenticated } from 'utils/authentication';
import { PreviewActionMenu } from 'components/preview/action-menu/action-menu';
import { useGetDocumentGrids } from '../../hooks/useGetDocumentGrids';
import { GridBlock } from '../../components/editor/GridDndEditor/GridBlock';
import { convertGridResponseToGridContentBlocks, getEditorMaxHeight } from '../../components/editor/GridDndEditor/gridHelper';
import { SignatureStatus } from '../../services/repositories/interfaces/SignatureRepository';
import WithSignatureModal from '../../components/editor/signatures/withSignatureModal';
import SignedSignature from '../../components/editor/signatures/signedSignature';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { gridPageMinHeightInPixels } from 'components/editor/shared/gridConfig';

export const PreviewGridPage: React.FC = () => {
  const { t } = useTranslation();
  const { documentId: documentIdFromParam } = useParams<{ documentId: string }>();
  const documentId = documentIdFromParam as string;
  const { data: documentData, status } = useGetDocumentByIdQuery(documentId);
  const { gridBlocksPreFormatted, status: gridQueryStatus } = useGetDocumentGrids(documentId);
  const { signatures, setSignatures, maxSignatureHeight } = usePreviewSignatures(documentId);

  const gridBlocks = gridBlocksPreFormatted ? convertGridResponseToGridContentBlocks(gridBlocksPreFormatted) : [];
  const documentTitle = documentData?.title || 'Untitled Document';
  const documentPrice = documentData?.price || 0.0;
  const documentProspectName = documentData?.prospect?.name || '';
  editorConfig.placeholderText = '';

  switch (status) {
    case 'error':
      return <NavigateToCinder path={CINDER_URLS.error} />;
    case 'success':
      if (gridQueryStatus === 'error') return <NavigateToCinder path={CINDER_URLS.error} />;
      return (
        <PreviewGrid
          header={
            <PreviewMenu
              documentId={documentId}
              documentTitle={documentTitle}
              documentProspectName={documentProspectName}
              documentPrice={documentPrice}
            />
          }
          banner={<Banner documentId={documentId}>{t('document.preview.banner')}</Banner>}
          main={
            <div
              data-testid="grid-preview"
              className="preview__editor__wrapper__grid"
              style={{ height: Math.max(gridPageMinHeightInPixels, getEditorMaxHeight(gridBlocks, maxSignatureHeight)) }}
            >
              {gridQueryStatus === 'success' &&
                gridBlocks?.map((block) => {
                  if (block.type === GridBlockType.TEXT) {
                    return (
                      <GridBlock key={block.id} gridBlock={block} gridSystemInPx={8}>
                        <FroalaEditor
                          model={block.content}
                          config={{
                            ...editorConfig,
                            editorClass: 'py-preview',
                            events: {
                              initialized() {
                                (this as any).edit.off();
                              },
                            },
                          }}
                        />
                      </GridBlock>
                    );
                  } else if (block.type === GridBlockType.IMAGE) {
                    return (
                      <GridBlock key={block.id} gridBlock={block} gridSystemInPx={8}>
                        <img
                          data-testid={`grid-image-${block.id}`}
                          key={`grid-image-${block.id}`}
                          src={block.content}
                          alt={`grid-image-${block.id}`}
                          className="grid_image_style"
                        />
                      </GridBlock>
                    );
                  }
                  return null;
                })}
              <div className="preview__grid__signatures">
                <div className="preview__signatures__container">
                  {Array.isArray(signatures) &&
                    signatures?.map(({ signatureBoxId, properties, ...rest }) => {
                      return rest.status === SignatureStatus.Signed ? (
                        <SignedSignature
                          signee={rest.signatureEvent}
                          position={properties.position}
                          dimensions={properties.dimensions}
                          signatureId={signatureBoxId}
                          key={signatureBoxId}
                          signedDate={rest.signatureEvent.signedDate}
                        />
                      ) : (
                        <WithSignatureModal
                          position={properties.position}
                          dimensions={properties.dimensions}
                          signatureId={signatureBoxId}
                          key={signatureBoxId}
                          assignedSignee={rest.assignedSignee}
                          documentId={documentId}
                          setSignatures={setSignatures}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          }
          footer={
            <Row justify="space-between" align="middle">
              <Col>
                <div className="preview__footer__document_price">
                  {documentData.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </div>
              </Col>
              {isAuthenticated() ? (
                <Col>
                  <PreviewActionMenu documentId={documentId} documentTitle={documentTitle} documentProspectName={documentProspectName} />
                </Col>
              ) : null}
            </Row>
          }
        />
      );
    case 'loading':
    default:
      return <WithLoadingIndicator isLoading={true} isLoaded={false}></WithLoadingIndicator>;
  }
};
