import { GridAddType } from '../grid/reduxStore/saveReducers';
import { GridBlockContent } from '../grid/reduxStore/editorSlice';
import { gridPixelSize } from '../shared/gridConfig';

export const maxImageWidthAllowedInPx = 500;
export const maxImageHeightAllowedInPx = 500;

export function convertGridResponseToGridContentBlocks(gridContents: GridAddType[]): GridBlockContent[] {
  return gridContents.map((gridContent) => {
    return {
      id: gridContent.gridId || '0',
      content: gridContent.htmlContent,
      type: gridContent.type,
      blockConfig: {
        id: gridContent.gridId || '0',
        height: gridContent.dimensions.height_px,
        width: gridContent.dimensions.width_px,
        y: gridContent.position.top_px,
        x: gridContent.position.left_px,
      },
    };
  });
}

export function getEditorMaxHeight(gridBlocks: GridBlockContent[], ...otherMaxValues: number[]) {
  const gridBlockMaxHeight = gridBlocks.reduce((currentMax, gridBlock) => {
    return Math.max(currentMax, gridBlock.blockConfig.y + (gridBlock.blockConfig.height || 0));
  }, 0);

  return Math.max(gridBlockMaxHeight, ...otherMaxValues) + gridPixelSize * gridPixelSize;
}

export function roundToNearestMultipleOfGridSize(x, y) {
  const roundedX = Math.round(x / gridPixelSize) * gridPixelSize;
  const roundedY = Math.round(y / gridPixelSize) * gridPixelSize;
  return { roundedX, roundedY };
}

export const calculateImageWidthAndHeight = (actualWidth: number, actualHeight: number) => {
  if (actualWidth <= maxImageWidthAllowedInPx && actualHeight <= maxImageHeightAllowedInPx) {
    return { calculatedWidth: actualWidth, calculatedHeight: actualHeight };
  }

  let calculatedWidth = actualWidth;
  let calculatedHeight = actualHeight;

  if (actualWidth > maxImageWidthAllowedInPx) {
    const aspectRatio = actualHeight / actualWidth;
    calculatedWidth = maxImageWidthAllowedInPx;
    calculatedHeight = calculatedWidth * aspectRatio;
  }

  if (calculatedHeight > maxImageHeightAllowedInPx) {
    const newAspectRatioAfterSettingHeight = calculatedWidth / calculatedHeight;
    calculatedHeight = maxImageHeightAllowedInPx;
    calculatedWidth = calculatedHeight * newAspectRatioAfterSettingHeight;
  }

  return { calculatedWidth, calculatedHeight };
};
