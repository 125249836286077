import React, { ReactHTML } from 'react';
import BlockSourceButton from './blockSourceButton';
import { Icons } from '../../icon/icons';

const { IcoTextbox } = Icons;

interface TextButtonProps {
  editorRef: any;
  textAs: keyof ReactHTML;
  title: string;
}

const TextButton: React.FunctionComponent<TextButtonProps> = ({ editorRef, textAs, title }) => {
  const handleDragStart = (e: React.DragEvent<HTMLElement>): void => {
    e.dataTransfer.setData('text/html', `<${textAs}>Lorem Ipsum</${textAs}>`);
  };

  const handleClick = (): void => {
    const editor = editorRef?.current?.editor;
    editor.html.insert(`<${textAs}>Lorem Ipsum</${textAs}>`);
    editor.undo.saveStep();
  };

  return <BlockSourceButton handleDragStart={handleDragStart} handleClick={handleClick} icon={<IcoTextbox />} title={title} />;
};

export default TextButton;
TextButton.displayName = 'TextButton';
