import { Endpoints } from 'utils/endpoints';
import { EditorRepository } from 'services/repositories/interfaces/EditorRepository';
import { VariablesGroup } from 'services/entities/VariablesEntity';
import { ProposalContentEntity } from 'services/entities/ProposalEntity';
import { FontEntity } from '../../entities/FontsEntity';
import { apiClient } from 'services/api';

export class ApiEditorRepository implements EditorRepository {
  public async getVariables(documentId: string): Promise<VariablesGroup> {
    const data = await apiClient.get<VariablesGroup>(Endpoints.getDocumentVariables(documentId));
    return data;
  }

  public async getProposalContent(proposalId: string): Promise<ProposalContentEntity> {
    const data = await apiClient.get<ProposalContentEntity>(Endpoints.getProposalContent(proposalId));

    if (!data) {
      throw new Error('Response was not ok');
    }
    return data;
  }

  public async getInstalledFonts(accountId: number): Promise<FontEntity[]> {
    const data = await apiClient.get<FontEntity[]>(Endpoints.getInstalledFonts(accountId));
    return data;
  }
}
