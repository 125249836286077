import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from 'components';
import { URLS } from 'utils/urls';

import './styles.less';

export function NewDocumentButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Button
      icon={<Icon name="IcoPlus" />}
      className="new-document-button"
      type="primary"
      variant="positive"
      onClick={() => {
        navigate(URLS.document);
      }}
      data-testid="new-document-button"
    >
      {t('new_document')}
    </Button>
  );
}
