import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import SocketClient from 'services/socket/SocketClient';

const SocketContext = createContext<SocketClient | undefined>(undefined);

export function useSocketClient() {
  const socketClient = useContext(SocketContext);
  if (socketClient === undefined) {
    throw new Error('useSocketClient must be used within a SocketProvider');
  }
  return socketClient;
}

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
  socketClient: PropTypes.instanceOf(SocketClient).isRequired,
};

export function SocketProvider({ children, socketClient }) {
  return <SocketContext.Provider value={socketClient}>{children}</SocketContext.Provider>;
}
