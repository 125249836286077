import { useTranslation } from 'react-i18next';

import { Heading } from 'components/typography';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import { DocumentSettingsForm } from './form';
import { LoadingIndicator } from './loading-indicator';

import './styles.less';

export function DocumentSettings({ documentId }: { documentId: string }) {
  const { data } = useGetDocumentByIdQuery(documentId);
  const { t } = useTranslation();

  return (
    <>
      <Heading level={5}>{t('document.form.title')}</Heading>
      {data ? <DocumentSettingsForm data={data} /> : <LoadingIndicator />}
    </>
  );
}
