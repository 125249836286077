import Froala from 'froala-editor';

interface Command {
  undo(): void;
  redo(): void;
}

export class UndoRedoManager {
  private isUndoCalled = false;
  private isRedoCalled = false;
  private undoStack: Command[];
  private redoStack: Command[];

  constructor() {
    this.undoStack = [];
    this.redoStack = [];
  }

  isUndoOrRedoCalled(): boolean {
    return this.isUndoCalled || this.isRedoCalled;
  }

  executeCommand(command: Command) {
    this.undoStack.push(command);
  }

  resetRedoStack(): void {
    this.redoStack = [];
  }

  resetUndoRedoCalledFlag(): void {
    this.isUndoCalled = false;
    this.isRedoCalled = false;
  }

  setIsUndoCalled(value): void {
    this.isUndoCalled = value;
  }

  setIsRedoCalled(value): void {
    this.isRedoCalled = value;
  }

  isUndoStackNotEmpty(): boolean {
    return this.undoStack.length != 0;
  }

  isRedoStackNotEmpty(): boolean {
    return this.redoStack.length != 0;
  }

  undoCommand(): void {
    const command = this.undoStack.pop();
    if (command) {
      command.undo();
      this.redoStack.push(command);
      /* eslint-disable no-console */
      console.log('Command undone');
    } else {
      this.isUndoCalled = false;
      /* eslint-disable no-console */
      console.log('Nothing to undo');
    }
  }

  redoCommand(): void {
    const command = this.redoStack.pop();
    if (command) {
      command.redo();
      this.undoStack.push(command);
      /* eslint-disable no-console */
      console.log('Command redone');
    } else {
      this.isRedoCalled = false;
      /* eslint-disable no-console */
      console.log('Nothing to redo');
    }
  }

  displayUndoRedo(): void {
    /* eslint-disable no-console */
    console.log('Undo Stack: ', this.undoStack);
    /* eslint-disable no-console */
    console.log('Redo Stack: ', this.redoStack);
  }
}

export class ContentChangeCommand implements Command {
  private itemRef: Froala;

  constructor(itemRef: Froala) {
    this.itemRef = itemRef;
  }

  undo(): void {
    this.itemRef.commands.undo();
  }

  redo(): void {
    this.itemRef.commands.redo();
  }
}
