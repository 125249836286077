import { Events } from './SocketEvents';
import { TSocketCallback } from './SocketClient';
import { UnSignedSignatureBox } from 'services/repositories/interfaces/SignatureRepository';

type SignatureSocketCallback = (data: UnSignedSignatureBox | UnSignedSignatureBox[]) => void;

export default class SignatureSocketClient {
  constructor() {
    this.handleSignatureEvents = this.handleSignatureEvents.bind(this);
  }

  private handleSignatureEvents(callback: SignatureSocketCallback, event: Events) {
    (this as any).subscribe(event, (data: UnSignedSignatureBox[]) => {
      callback(data);
    });
  }

  public getSignaturesOnMount(this: any, callback: (data: any) => any) {
    (this as any).subscribeOnce(Events.SIGNATURE_GET_ALL, (data: any) => {
      callback(data);
    });
  }

  public handleSignatureUpdate(this: any, callback: SignatureSocketCallback) {
    this.handleSignatureEvents(this, callback, Events.SIGNATURE_UPDATE);
  }

  public handleSignatureAdded(this: any, callback: SignatureSocketCallback) {
    this.handleSignatureEvents(this, callback, Events.SIGNATURE_ADD);
  }

  public handleSignatureDelete(this: any, callback: SignatureSocketCallback) {
    this.handleSignatureEvents(this, callback, Events.SIGNATURE_DELETE);
  }

  public publishSignatureContent(this: any, data: UnSignedSignatureBox | UnSignedSignatureBox[], event: string, callback: TSocketCallback) {
    this.publish(event, data, callback);
  }
}
