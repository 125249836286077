import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { ApiEditorRepository } from '../services/repositories/implementations/ApiEditorRepository';

export function useInstalledFonts(accountId: number, enabled = true) {
  const editorRepository = new ApiEditorRepository();
  const { data, isError, isFetched } = useQuery(QueryKeys.fonts, () => editorRepository.getInstalledFonts(accountId), { enabled });
  const result = !data ? [] : data;
  return {
    result,
    isError,
    isFetched,
  };
}
