import { useState } from 'react';
import { UsedVariable } from '../components/editor/sidepanel/variables/interfaces';

export function useUsedVariables() {
  const [usedVariables, setUsedVariables] = useState<UsedVariable[]>([]);

  const handleUsedVariablesParsing = (documentContent: string) => {
    const getUsedVariablesWorker: Worker = new Worker(
      new URL('../components/editor/sidepanel/variables/getUsedVariablesWorker.ts', import.meta.url)
    );
    const message = {
      htmlContent: documentContent,
    };

    getUsedVariablesWorker.postMessage(message);
    getUsedVariablesWorker.onmessage = (event) => {
      if (event.data) {
        setUsedVariables(event.data.usedVariables);
      } else {
        setUsedVariables([]);
      }
    };
  };

  return { usedVariables, handleUsedVariablesParsing };
}
