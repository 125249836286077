import { ImageDrop } from '../file/image-drop';
import React, { useState } from 'react';
import { DraggableGridBlockWrapper } from './DraggableGridBlockWrapper';
import { GridBlockContent } from '../grid/reduxStore/editorSlice';
import SkeletonImageLoading from '../../skeletonImageLoading';
import { Block } from './models/block.model';
import { AddBlock } from './models/addBlock.model';
import { GridBlockType } from '../shared/gridBlockType';
import './style.less';

interface GridImageLoaderProps {
  documentId: string;
  onDragStop?: (e, data, item: Block) => void;
  onResizeStop: (e, direction, ref, delta, item: Block) => void;
  handleStop: (e, data, item: Block) => void;
  handleDrag: (e, block: Block) => void;
  gridBlocks: GridBlockContent[];
  gridPixelSize: number;
}

const editorSkeletonImageLoader = 'editor-skeleton-image-loader';

export const GridImageLoader: React.FC<GridImageLoaderProps> = ({
  documentId,
  gridBlocks,
  gridPixelSize,
  handleDrag,
  handleStop,
  onResizeStop,
}) => {
  const [loadingComponents, setLoadingComponents] = useState<AddBlock[]>([]);
  return (
    <>
      <ImageDrop documentId={documentId} setLoadingComponents={setLoadingComponents} />
      {loadingComponents.map((component: AddBlock, index) => {
        return (
          <SkeletonImageLoading
            key={index}
            imageKey={index}
            xAxis={component.x}
            yAxis={component.y}
            width={component.width || 0}
            height={component.height || 0}
            className={editorSkeletonImageLoader}
          />
        );
      })}
      {gridBlocks
        .filter((gridBlock) => gridBlock.type === GridBlockType.IMAGE)
        .map((gridImageBlock, index) => {
          return (
            <DraggableGridBlockWrapper
              key={`grid-image-${index}`}
              gridBlock={gridImageBlock}
              gridSystemInPx={gridPixelSize}
              handleStop={handleStop}
              handleDrag={handleDrag}
              handleResizeStop={onResizeStop}
            >
              <img
                data-testid={`grid-image-${index}`}
                src={gridImageBlock.content}
                alt={`grid-image-${index}`}
                className="grid_image_style"
                draggable={false}
              />
            </DraggableGridBlockWrapper>
          );
        })}
    </>
  );
};
