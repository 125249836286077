import { renderToStaticMarkup } from 'react-dom/server';
import { VariablesGroup, VariableEntity } from 'services/entities/VariablesEntity';

export const formatOptions = (variables: VariablesGroup) => {
  if (!variables) {
    return [];
  }
  const { user, proposal, client } = variables;
  return [...user, ...proposal, ...client];
};

export const options = {
  trigger: '[',
  containerClass: 'variables-container',
  lookup: (variable: VariableEntity) => variable.friendlyName,
  fillAttr: 'fullName',
  allowSpaces: true,
  selectTemplate: function (item: { original: VariableEntity }) {
    return renderToStaticMarkup(
      <span className="fr-deletable fr-tribute" contentEditable="false">
        <span className="text-variable" data-variable-name={item.original.name} data-variable-type={item.original.type}>
          {item.original.value || item.original.friendlyName}
        </span>
      </span>
    );
  },
  menuItemTemplate: function (item: { original: VariableEntity }) {
    const { original } = item;
    const { value, type, friendlyName } = original;
    const variableValue = value ? value : '';

    const variableHeaders = {
      user: 'User Variable',
      proposal: 'Document Variable',
      client: 'Client Variable',
    };

    const variableHeader = variableHeaders[type];

    return renderToStaticMarkup(
      <span>
        {variableHeader && <h3>{variableHeader}</h3>}
        {friendlyName}
        {variableValue && (
          <>
            <span className="text-variable-vr">|</span>
            <span className="text-variable-value">{variableValue}</span>
          </>
        )}
      </span>
    );
  },
};
