import FroalaEditorWrapper from '../grid/editor-wrapper';
import React from 'react';
import { default as DragIcon } from '../../icon/icons/IcoDrag';
import { default as ResizeIcon } from '../../icon/icons/IcoGridResize';
import { GridBlockContent } from '../grid/reduxStore/editorSlice';
import { VariablesGroup } from '../../../services/entities/VariablesEntity';

interface EditorGridBlockProps {
  hoveredBlocksIds: string[];
  block: GridBlockContent;
  configOptions?: object | undefined;
  variables?: VariablesGroup;
  editorResizeHandler: (editorId: string, rect: DOMRectReadOnly) => void;
  blockContentChangeHandler: (gridBlockId: string, blockContent: string) => void;
}
export const EditorGridBlock: React.FC<EditorGridBlockProps> = ({
  hoveredBlocksIds,
  block,
  configOptions,
  variables,
  editorResizeHandler,
  blockContentChangeHandler,
}) => {
  return (
    <div className={`grid_block_${block.id} ${hoveredBlocksIds.includes(block.id) ? 'grid_block_hovered' : ''}`}>
      <>
        {hoveredBlocksIds.includes(block.id) && (
          <>
            <div className="editor__page__grid-drag-handle">
              <DragIcon />
            </div>
            <div className="editor__page__grid-resize-handle">
              <ResizeIcon />
            </div>
          </>
        )}
      </>
      <FroalaEditorWrapper
        key={block.id}
        gridBlockId={block.id}
        configOptions={configOptions}
        variables={variables}
        documentContent={block.content}
        editorResizeHandler={editorResizeHandler}
        contentChangeHandler={blockContentChangeHandler}
      />
    </div>
  );
};

EditorGridBlock.displayName = 'EditorGridBlock';
